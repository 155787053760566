const difficult = [
    {
        id: "MNT",
        question: `Wie nennt man eine ausgewachsene Sardine?`,
        answers: [`Pilchard`, `Hering`, `Sardelle`, `##Sprotte`],
    },
    {
        id: "IKF",
        question: `In welcher Sportart war Tom Cruise hervorragend?`,
        answers: [`##Wrestling`, `Leichtathletik`, `Boxen`, `Baseball`],
    },
    {
        id: "MJQ",
        question: `Wie hieß ein bekannter DDR-Whisky?`,
        answers: [`Brenner`, `##Falckner`, `Turmfalke`, `Roter Adler`],
    },
    {
        id: "POK",
        question: `Was gibt man in Lumen an?`,
        answers: [`Lichtstärke`, `##Lichtstrom`, `Beleuchtungsstärke`, `Elektrische Feldstärke`],
    },
    {
        id: "IGP",
        question: `Wie viele Spitzen hat der Stern der Zeitschrift „Stern“?`,
        answers: [`3`, `5`, `##6`, `8`],
    },
    {
        id: "UGH",
        question: `Was ist ein Kalpak?`,
        answers: [`ein Mantel`, `eine Hose`, `eine Bluse`, `##eine Mütze`],
    },
    {
        id: "ZAR",
        question: `Wie hieß Republikflucht aus der DDR seit 1961 offiziell?`,
        answers: [`unerlaubter Grenzübertritt`, `Grenzverletzung`, `##ungesetzlicher Grenzübertritt`, `Verletzung des antifaschistischen Schutzwalls`],
    },
    {
        id: "SXJ",
        question: `In welchem Jahre kamen die ersten CDs auf den Markt?`,
        answers: [`1978`, `##1982`, `1986`, `1990`],
    },
    {
        id: "EZV",
        question: `„Wandelstern“ ist das veraltete Wort für ... ?`,
        answers: [`Komet`, `##Planet`, `Sonne`, `Trabant`],
    },
    {
        id: "BAB",
        question: `Welches Tier gibt es nicht?`,
        answers: [`Wespenspinne`, `##Graubartadler`, `Dampfschiffente`, `Geburtshelferkröte`],
    },
    {
        id: "BTQ",
        question: `Was ist Pomologie?`,
        answers: [`Apfelkunde`, `Weinbaukunde`, `Getreidekunde`, `##Obstbaukunde`],
    },
    {
        id: "TPM",
        question: `Wieviele Tage liegen zwischen Ostern und Pfingsten?`,
        answers: [`40`, `##50`, `26`, `65`],
    },
    {
        id: "VLF",
        question: `Wieviel Bäume kann ein Biber im Jahr fällen?`,
        answers: [`##etwa 200`, `etwa 50`, `etwa 100`, `etwa 300`],
    },
    {
        id: "ZPW",
        question: `Welchen Betrag kann man nicht mit genau 10 der gängigen Euromünzen bezahlen?`,
        answers: [`0,11€`, `2,24€`, `6,99€`, `##18,03€`],
    },
    {
        id: "FNU",
        question: `Welche Ente gibt es nicht?`,
        answers: [`Mandarinente`, `##Höckerente`, `Pfeifente`, `Moschusente`],
    },
    {
        id: "XDZ",
        question: `Wie nennt man den Drahtkorb, der den Korken auf der Sektflasche hält?`,
        answers: [`Kronette`, `##Agraffe`, `Boutine`, `Flanage`],
    },
    {
        id: "CVH",
        question: `Wie nennt man das metallische Ende eines Schnürsenkels?`,
        answers: [`##Pinke`, `Stift`, `Finke`, `Henke`],
    },
    {
        id: "LEI",
        question: `Was bedeutet der Begriff „Derealisationserleben“?`,
        answers: [`Jahrmarktsattraktion`, `Wutanfall`, `spontane Entzündung`, `##verfremdete Wahrnehmung`],
    },
    {
        id: "FHD",
        question: `Welche deutsche Künstlerin wird als „Godmother of Punk“ bezeichnet?`,
        answers: [`Judith van Hel`, `##Nina Hagen`, `Nena`, `Bela B`],
    },
    {
        id: "JRQ",
        question: `War die Maßnahme erfolgreich, dann ist die Technik durch ein ... ?`,
        answers: [`Back-up back to up`, `Plug-in plug to in`, `##Update up to date`, `Download down to load`],
    },
    {
        id: "HDQ",
        question: `Was macht man für gewöhnlich mit einem „Petit Four“?`,
        answers: [`anziehen`, `##essen`, `fahren`, `hören`],
    },
    {
        id: "IBU",
        question: `Welcher Megahit klingt mit einem markanten letzten Einsatz der Panflöte aus?`,
        answers: [`Poker Face`, `La Isla Bonita`, `My Heart Will Go On`, `##Whenever, Wherever`],
    },
    {
        id: "OLD",
        question: `Wobei handelt es sich um ein sogenanntes Unterscheidungszeichen?`,
        answers: [`##PB für Paderborn`, `PK für Polizeikommissar`, `Pt für Plati`, `PS für Pferdestärke`],
    },
    {
        id: "GBC",
        question: `Die erste bezahlte Fracht, die im Jahr 1836 auf einer deutschen Eisenbahnlinie befördert wurde, bestand aus ... ?`,
        answers: [`einem Ballen Leder`, `##zwei Fässern Bier`, `drei Kisten Schrauben`, `vier Sack Kartoffeln`],
    },
    {
        id: "SWT",
        question: `Gegen welche Krankheit wurde die hervorragende Wirksamkeit von Lebertran 1824 entdeckt?`,
        answers: [`Mumps`, `Röteln`, `##Rachitis`, `Windpocken`],
    },
    {
        id: "UVD",
        question: `Welches Wort bezeichnet keine Wolkenformation?`,
        answers: [`Cumulus`, `Stratus`, `Cirrus`, `##Bicarius`],
    },
    {
        id: "QEI",
        question: `Eine Chanteuse ... ?`,
        answers: [`dichtet`, `spielt Geige`, `verziert Torten`, `##singt`],
    },
    {
        id: "IFD",
        question: `Ein Verein erlangt Rechtsfähigkeit durch Eintragung ins Vereinsregister beim ... ?`,
        answers: [`##Amtsgericht`, `Bundesamt`, `Bürgerbüro`, `Ordnungsamt`],
    },
    {
        id: "CND",
        question: `Was ist ein Delinquent?`,
        answers: [`Forscher`, `3-Sterne-Koch`, `##Straftäter`, `Musiker`],
    },
    {
        id: "ZIO",
        question: `Ein japanisches Gericht aus erkaltetem, gesäuertem Reis, ergänzt um weitere Zutaten heißt ... ?`,
        answers: [`Kōbe`, `##Sushi`, `Fugu`, `Nightingale`],
    },
    {
        id: "JLH",
        question: `Was ist eine typische südtiroler Brotart?`,
        answers: [`##Schüttelbrot`, `Wackelbrot`, `Trommelbrot`, `Zitterbrot`],
    },
    {
        id: "ZZA",
        question: `Wo ist häufig eine „Frankfurter Pfanne“ zu finden?`,
        answers: [`in Hochöfen`, `auf Bahngleisen`, `##auf Dächern`, `in Kanalisationen`],
    },
    {
        id: "LKQ",
        question: `Was erhält man in einem Kaffeehaus, wenn man sich einen „Pharisäer“ bestellt?`,
        answers: [`Kakao mit Rum und Sahne`, `Tee mit Rum`, `##Kaffee mit Rum und Sahne`, `Heiße Limonade mit Rum`],
    },
    {
        id: "ZXW",
        question: `Bei welcher Berufsgruppe spielt die „Enfleurage“ eine große Rolle?`,
        answers: [`##Parfümeure`, `Pharmazeuten`, `Kostümbildner`, `Konditoren`],
    },
    {
        id: "ZDG",
        question: `Einen Rohling zur Münzenprägung nennt man ... ?`,
        answers: [`##Ronde`, `Sende`, `Tunde`, `Ginde`],
    },
    {
        id: "RKR",
        question: `Wie nennt man einen gutartigen Muskeltumor?`,
        answers: [`Karzinom`, `Fibrose`, `##Myom`, `Mimose`],
    },
    {
        id: "QZV",
        question: `Wie heißt die Unterteilung des britischen Pfunds?`,
        answers: [`##Pence`, `Cent`, `Crown`, `Schilling`],
    },
    {
        id: "OEY",
        question: `Wie nennt man den Raub von Kunstwerken zum Zwecke der Erpressung?`,
        answers: [`Kunstnapping`, `##Artnapping`, `Paintnapping`, `Colornapping`],
    },
    {
        id: "HXR",
        question: `Wie wird ein ungehobelter, unhöflicher Mensch auch bezeichnet?`,
        answers: [`##Stoffel`, `Steffel`, `Stiffel`, `Stuffel`],
    },
    {
        id: "UQR",
        question: `Wo ist ein „Transfermarkt“ üblich?`,
        answers: [`Bauhandel`, `Schwarzmarkt`, `##Profifußball`, `Flohmarkt`],
    },
    {
        id: "QXX",
        question: `Welche im Taunus gelegene Einrichtung lockt alljährlich Hunderttausende Besucher an?`,
        answers: [`##Opel-Zoo`, `BMW-Theater`, `Mercedes-Park`, `Audi-Aquarium`],
    },
    {
        id: "BBR",
        question: `Die verschleißlose Dauerbremse eines LKW's nennt man ... ?`,
        answers: [`Reticular`, `Retainer`, `Retraction`, `##Retarder`],
    },
    {
        id: "YWT",
        question: `Wie heißt eine graphische Drucktechnik im künstlerischen Bereich?`,
        answers: [`Bakelitschnitt`, `##Linolschnitt`, `Alaunschnitt`, `PVC-Schnitt`],
    },
    {
        id: "QOH",
        question: `Wie heißt ein chromgegerbtes Glattleder von Kalb oder Schaf mit vollen Narben?`,
        answers: [`##Nappaleder`, `Cordovan`, `Aalleder`, `Boxcalf`],
    },
    {
        id: "BFT",
        question: `Bei welcher Insel liegt an der Südküste die „Schweinebucht“?`,
        answers: [`Ibiza`, `Zypern`, `##Kuba`, `Seychellen`],
    },
    {
        id: "PVF",
        question: `Welcher Zweig wird traditionell am 4. Dezember in die Vase gestellt, um an Heiligabend zu blühen?`,
        answers: [`Ursulazweig`, `Stephanzweig`, `Magdalenenzweig`, `##Barbarazweig`],
    },
    {
        id: "LZO",
        question: `Wie bezeichnet man etwas, das sehr gründlich und umfassend ist?`,
        answers: [`prototypisch`, `orthodox`, `exogen`, `##profund`],
    },
    {
        id: "VLC",
        question: `Manche Übergewichtige versuchen ihr Gewicht zu reduzieren mit der Methode ... ?`,
        answers: [`AvD`, `FcB`, `AkA`, `##FdH`],
    },
    {
        id: "YXA",
        question: `Das Relief des französischen Feingebäcks „Madeleine“ ähnelt welcher charakteristischen Form?`,
        answers: [`##Jakobsmuschel`, `Hexagon`, `Zylinder`, `Pyramide`],
    },
    {
        id: "VNB",
        question: `Zur Arbeitskleidung eines Bergmannes gehört ... ?`,
        answers: [`das Bauchfell`, `die Handwolle`, `##das Arschleder`, `der Kniepelz`],
    },
    {
        id: "LXO",
        question: `Welcher Cocktail erfreut sich laut Internationaler Barkeeper-Vereinigung zunehmender Beliebtheit?`,
        answers: [`##Espresso Martini`, `Cappuccino Cosmopolitan`, `Flat White Margarita`, `Latte Macchiato Mojito`],
    },
    {
        id: "TJE",
        question: `Welches Getreide wird nach Streichung eines Buchstabens zu Fischeiern?`,
        answers: [`##Roggen`, `Gerste`, `Hafer`, `Weizen`],
    },
    {
        id: "SBC",
        question: `Matcha-Tee ist ... ?`,
        answers: [`orange`, `gelb`, `rot`, `##grün`],
    },
    {
        id: "AXL",
        question: `Wie heißt das der Sonne nächstgelegene Sternsystem?`,
        answers: [`Delta Centauri`, `Gamma Centauri`, `##Alpha Centauri`, `Beta Centauri`],
    },
    {
        id: "BPY",
        question: `Wie groß ist ein Tennisfeld (Einzel)?`,
        answers: [`32,21m x 12,42m`, `##23,77m x 8,23m`, `16m x 6m`, `35m x 15m`],
    },
    {
        id: "GHY",
        question: `In welcher Stadt liegt Karl Marx begraben?`,
        answers: [`Trier`, `##London`, `Berlin`, `Paris`],
    },
    {
        id: "ZSZ",
        question: `Wie wird die Verleihung der Doktorwürde genannt?`,
        answers: [`##Promotion`, `Affilitation`, `Devotion`, `Habilitation`],
    },
    {
        id: "BUW",
        question: `In Berlin gibt es die U-Bahn seit dem Jahre ... ?`,
        answers: [`1892`, `##1902`, `1912`, `1922`],
    },
    {
        id: "KYJ",
        question: `Was bedeutet die Abkürzung DVD?`,
        answers: [`Digital Video Demand`, `Digital Visual Disc`, `Domaine Video Disc`, `##Digital Versatile Disc`],
    },
    {
        id: "DJA",
        question: `Wie nennt man die Lobrede auf einen Preisträger?`,
        answers: [`Tirade`, `Konjugation`, `##Laudatio`, `Ovation`],
    },
    {
        id: "BEO",
        question: `Am wievielten Hochzeitstag feiert man die Korallenhochzeit?`,
        answers: [`25. Hochzeitstag`, `30. Hochzeitstag`, `##35. Hochzeitstag`, `40. Hochzeitstag`],
    },
    {
        id: "VDF",
        question: `In welchem Jahr kamen die ersten Digitalkameras auf den Markt?`,
        answers: [`##1990`, `1992`, `1994`, `1996`],
    },
    {
        id: "ESO",
        question: `In welcher Stadt werden alle Nobelpreise ausser dem Friedensnobelpreis übergeben?`,
        answers: [`Kopenhagen`, `Prag`, `Wien`, `##Stockholm`],
    },
    {
        id: "ZSK",
        question: `Welches nordische Land prägte den Namen für einen grob gestrickten Pullover?`,
        answers: [`##Norwegen`, `Dänemark`, `Finnland`, `Schweden`],
    },
    {
        id: "TEO",
        question: `Jules Verne war ein französischer ... ?`,
        answers: [`Politiker`, `##Schriftsteller`, `Komponist`, `Maler`],
    },
    {
        id: "NFS",
        question: `Der Krippenstein in Oberösterreich (2.108 m) hat eine Aussichtsplattform mit Namen ... ?`,
        answers: [`Green Blossom`, `##Five Fingers`, `White Heads`, `Second Hands`],
    },
    {
        id: "USM",
        question: `Was ist „Halitit“?`,
        answers: [`Cayennepfeffer`, `Rohrzucker`, `##Steinsalz`, `Weißmehl`],
    },
    {
        id: "RNI",
        question: `In welches deutsche Rechtsgebiet fällt der Begriff der „Zugewinngemeinschaft“?`,
        answers: [`Arbeitsrecht`, `##Familienrecht`, `Staatsrecht`, `Wirtschaftsrecht`],
    },
    {
        id: "XUQ",
        question: `Was wird als Inbegriff der klassischen polnischen Küche angesehen?`,
        answers: [`Coq au Vin`, `Paella`, `##Bigos`, `Smørrebrød`],
    },
    {
        id: "EYS",
        question: `Streng wissenschaftlich nennt man alle Bücher, die bis zum 31.12.1500 gedruckt wurden, ... ?`,
        answers: [`Bibliographien`, `Frühdrucke`, `##Inkunabeln`, `Kupferdruckstiche`],
    },
    {
        id: "YRL",
        question: `Was ist der sogenannte „Pischinger Raum“?`,
        answers: [`Raum zwischen den Atomen`, `Bereich zwischen Atmosphäre und Weltraum`, `##Extrazelluläre Matrix im Körpergewebe`, `Behälter mit einem Vakuum`],
    },

    
    // {
    //     id: "DOT",
    //     question: `Welcher Planet ist auch als „Morgenstern“ oder „Abendstern“ bekannt?`,
    //     answers: [`Mars`, `##Venus`, `Jupiter`, `Saturn`],
    // },
    // {
    //     id: "SVT",
    //     question: `Wie oft im Jahr brüten Tauben?`,
    //     answers: [`Bis zu 3 Mal`, `##Bis zu 5 Mal`, `Bis zu 7 Mal`, `Bis zu 9 Mal`],
    // },
    // {
    //     id: "JZE",
    //     question: `Welcher dieser Einheiten ist am kleinsten?`,
    //     answers: [`Mikrometer`, `##Femtometer`, `Nanometer`, `Pikometer`],
    // },
    // {
    //     id: "WLB",
    //     question: `Für welche Vereinigung steht die Abkürzung IATA?`,
    //     answers: [`##Lufttransportgewerbe`, `Bahnfahrergewerkschaft`, `Seefahrerverbindung`, `Tennisspielerkomitee`],
    // },
    // {
    //     id: "KDF",
    //     question: `In welchem Land Europas konnte man zuerst ein McDonalds-Restaurant besuchen?`,
    //     answers: [`Niederlande`, `Belgien`, `##Deutschland`, `Frankreich`],
    // },
    // {
    //     id: "VMW",
    //     question: `Wen bezeichnet man als „Godfather of Punk“?`,
    //     answers: [`Iggy Rock`, `Iggy Mod`, `##Iggy Pop`, `Iggy Hot`],
    // },
    // {
    //     id: "FZD",
    //     question: `Welche Vorsatzsilbe steht für eine Trillion?`,
    //     answers: [`##Exa`, `Tera`, `Peta`, `Zeta`],
    // },
    // {
    //     id: "MVP",
    //     question: `Welcher Begriff umfasst unterhaltende, schöngeistige Literatur, Romane und Erzählungen?`,
    //     answers: [`##Belletristik`, `Romanistik`, `Stilistik`, `Realistik`],
    // },
    // {
    //     id: "FKY",
    //     question: `Welche Frucht ist die Paradiesfeige?`,
    //     answers: [`Mango`, `Kiwi`, `##Banane`, `Grapefruit`],
    // },
];

export default difficult;