const personal = [
    {
        id: "AJH",
        type: "scale",
        question: `Wie hoch schätzt du deinen Perfektionismus ein?`,
    },
    {
        id: "NAH",
        type: "scale",
        question: `Wie sehr hast du deine Kindheit genossen?`,
    },
    {
        id: "WWB",
        type: "scale",
        question: `Wie gerne stehst du im Mittelpunkt?`,
    },
    {
        id: "BLA",
        type: "scale",
        question: `Wie risikofreudig bist du?`,
    },
    {
        id: "IZV",
        type: "scale",
        question: `Wie sehr bist du abergläubig?`,
    },
    {
        id: "PDK",
        type: "scale",
        question: `Wie stark würdest du dich als optimistisch bezeichnen?`,
    },
    {
        id: "YZC",
        type: "scale",
        question: `Wie wichtig ist dir persönliches Wachstum und Entwicklung?`,
    },
    {
        id: "EGE",
        type: "scale",
        question: `Wie stark würdest du deine Neigung einstufen, Dinge auf morgen zu verschieben?`,
    },
    {
        id: "ISQ",
        type: "scale",
        question: `Wie stark würdest du deine Reizbarkeit einstufen?`,
    },
    {
        id: "WEP",
        type: "scale",
        question: `Wie stark ist dein Bedürfnis nach Abenteuer und neuen Erfahrungen?`,
    },
    {
        id: "WEY",
        type: "scale",
        question: `Wie wichtig ist dir Geld?`,
    },
    {
        id: "ZEY",
        type: "scale",
        question: `Wie wichtig ist dir deine berufliche Karriere?`,
    },
    {
        id: "KWL",
        type: "scale",
        question: `Wie sehr neigst du dazu neue Dinge auszuprobieren?`,
    },
    {
        id: "PCE",
        type: "scale",
        question: `Wie sehr legst du Wert auf deine körperliche Gesundheit?`,
    },
    {
        id: "LOM",
        type: "scale",
        question: `Wie sehr beeinflusst die Vergangenheit deine Entscheidungen?`,
    },
    {
        id: "MPY",
        type: "scale",
        question: `Wie hoch schätzt du deine Fähigkeit zur Zusammenarbeit mit anderen ein?`,
    },
    {
        id: "DGZ",
        type: "scale",
        question: `Wie oft reflektierst du über dein eigenes Verhalten?`,
    },
    {
        id: "ENB",
        type: "scale",
        question: `Wie sehr bist du von anderen Meinungen beeinflussbar?`,
    },
    {
        id: "BCM",
        type: "scale",
        question: `Wie sehr stehst du Veränderungen aufgeschlossen gegenüber?`,
    },
    {
        id: "QEK",
        type: "scale",
        question: `Wie wichtig ist es für dich, alles unter Kontrolle zu haben?`,
    },
    {
        id: "BND",
        type: "rating",
        question: `Wie gut kannst du Kritik annehmen?`,
    },
    {
        id: "WXS",
        type: "rating",
        question: `Wie gut würdest du dich auf einer einsamen Insel durchschlagen?`,
    },
    {
        id: "LSD",
        type: "rating",
        question: `Wie gut kannst du mit Stress umgehen?`,
    },
    {
        id: "AXX",
        type: "rating",
        question: `Wie stark schätzt du dich als überzeugend ein?`,
    },
    {
        id: "VAA",
        type: "rating",
        question: `Wie bewertest du deine Fähigkeiten im Umgang mit Technologie?`,
    },
    {
        id: "WRZ",
        type: "rating",
        question: `Wie gut kannst du Geheimnisse bewahren?`,
    },
    {
        id: "QDB",
        type: "rating",
        question: `Wie gut bist du darin, Konflikte zu lösen?`,
    },
    {
        id: "NKD",
        type: "classic",
        question: `Welche Superkraft würdest du gerne besitzen?`,
        answers: [`Telekinese`, `Unsichtbarkeit`, `Zeitreisen`, `Fliegen`],
    },
    {
        id: "CBX",
        type: "classic",
        question: `Wo würdest du am liebsten Urlaub machen?`,
        answers: [`In den Bergen`, `In einer Schnee-Region`, `In einer Großstadt-Metropole`, `Auf einer Südsee-Insel`],
    },
    {
        id: "VRZ",
        type: "classic",
        question: `Welche Tageszeit ist deine bevorzugte?`,
        answers: [`Morgens`, `Mittags`, `Abends`, `Nachts`],
    },
    {
        id: "LZV",
        type: "classic",
        question: `Welches Instrument könntest du dir am ehesten vorstellen zu spielen?`,
        answers: [`Geige`, `Akkordeon`, `Schlagzeug`, `E-Gitarre`],
    },
    {
        id: "YVM",
        type: "classic",
        question: `Wovon werden deine Entscheidungen am stärksten beeinflusst?`,
        answers: [`Vom Verstand`, `Vom Herzen`, `Aus Erfahrungen`, `Vom Bauchgefühl`],
    },
    {
        id: "EQC",
        type: "classic",
        question: `Welches Tier passt am ehesten zu deiner Persönlichkeit?`,
        answers: [`Goldhamster`, `Papagei`, `Löwe`, `Schaf`],
    },
    {
        id: "PRU",
        type: "classic",
        question: `Wo würdest du am liebsten wohnen?`,
        answers: [`Holzhütte im Wald`, `Bungalow am Strand`, `Luxus-Apartment im Stadtzentrum`, `Kleines Schloss auf dem Land`],
    },
    {
        id: "CQB",
        type: "classic",
        question: `Wo würdest du am ehesten leben wollen?`,
        answers: [`Amerika`, `Afrika`, `Russland`, `China`],
    },
    {
        id: "QST",
        type: "classic",
        question: `Welches Verkehrsmittel ziehst du vor, um zu reisen?`,
        answers: [`Auto`, `Zug`, `Flugzeug`, `Fahrrad`],
    },
    {
        id: "VGN",
        type: "classic",
        question: `Welches Getränk würdest du auf einer Party wählen?`,
        answers: [`Cocktail`, `Bier`, `Wein`, `Softdrink`],
    },
    {
        id: "JOT",
        type: "classic",
        question: `Welche Art von Musik bevorzugst du?`,
        answers: [`Pop`, `Rock`, `Klassik`, `Hip-Hop`],
    },
    {
        id: "CYE",
        type: "classic",
        question: `Auf welchen Sinn würdest du am ehesten verzichten?`,
        answers: [`Geruchssinn`, `Tastsinn`, `Sehsinn`, `Gehörsinn`],
    },
    {
        id: "ADR",
        type: "classic",
        question: `Welches Wetter magst du am liebsten?`,
        answers: [`Sonnig`, `Regnerisch`, `Schneereich`, `Bewölkt`],
    },
    {
        id: "AWB",
        type: "classic",
        question: `Was würdest du, neben dem Lebensnotwendigen, auf eine einsame Insel mitnehmen?`,
        answers: [`Eine Matratze`, `Einen Spiegel`, `Bleistift und Papier`, `Ein Kosmetikartikel deiner Wahl`],
    },
    {
        id: "GAL",
        type: "classic",
        question: `Du wirst auf einer einsamen Insel abgesetzt. Welchen Gegenstand würdest du mitnehmen?`,
        answers: [`Feuerzeug`, `Messer`, `Gefäß`, `Plane`],
    },
    {
        id: "KNX",
        type: "classic",
        question: `In welcher Zeitform verschwendest du die meisten Gedanken?`,
        answers: [`In der Vergangenheit`, `In der Gegenwart`, `In der Zukunft`],
    },
    {
        id: "YLV",
        type: "classic",
        question: `In welchem Film / Serien kannst du dir am ehesten eine Rolle vorstellen?`,
        answers: [`Kindsköpfe`, `Fantastic Four`, `James Bond`, `Avatar`],
    },
    {
        id: "ZGT",
        type: "classic",
        question: `Welche Art von Restaurant bevorzugst du für ein romantisches Abendessen?`,
        answers: [`Italienisch`, `Französisch`, `Asiatisch`, `Deutsch`],
    },
    {
        id: "WIC",
        type: "classic",
        question: `In welche Zeit würdest du gerne kurzeitig reisen?`,
        answers: [`100 Jahre voraus`, `100 Jahre zurück`, `30 Jahre zurück`],
    },
    {
        id: "TJX",
        type: "classic",
        question: `Welches Tier wärst du gerne für einen Tag?`,
        answers: [`Löwe`, `Vogel`, `Delphin`, `Hund`],
    },
    {
        id: "NYK",
        type: "classic",
        question: `Welche Art von Urlaubsunterkunft bevorzugst du?`,
        answers: [`Hotel`, `Ferienhaus`, `Camping`, `Luxusresort`],
    },
    {
        id: "QDV",
        type: "classic",
        question: `Welcher Prominente sagt dir am ehesten zu?`,
        answers: [`Roland Kaiser`, `Boris Becker`, `Til Schweiger`, `Dieter Bohlen`],
    },
    {
        id: "WAI",
        type: "classic",
        question: `Du kannst für einen Tag unsichtbar sein. Was machst du?`,
        answers: [`Jemanden verfolgen`, `Geld stehlen`, `Leute ärgern`, `Gutes tun`],
    },
    {
        id: "ZNJ",
        type: "classic",
        question: `Dein allerletzter Arbeitstag ist vorbei. Was ist dein Gedanke?`,
        answers: [`Und nun`, `Endlich!`, `Reisen`, `Was mache ich jetzt zuerst`],
    },
    {
        id: "RUX",
        type: "classic",
        question: `Welche Frage würdest du einem Außerirdischen stellen?`,
        answers: [`Gibt es den Raumanzug auch von Adidas`, `Wieviel PS hat das Raumschiff`, `Gibt es auch hübsche Wesen da draußen`, `Kann ich mal mitfliegen`],
    },
    {
        id: "DCD",
        type: "classic",
        question: `Du hast einen Wunsch frei. Was würdest du tun?`,
        answers: [`sofort einlösen`, `aufheben für später`],
    },
    {
        id: "FCZ",
        type: "classic",
        question: `Du kommst vom Nacktbaden und alle Sachen sind weg. Was tust du?`,
        answers: [`Jemanden um Hilfe bitten`, `Sachen von anderen klauen`, `selbstbewusst fortgehen`, `sich etwas aus der Umgebung basteln`],
    },
    {
        id: "MCK",
        type: "classic",
        question: `Du kannst jeden Tag nur noch eine Sache essen. Welche wäre das?`,
        answers: [`Kartoffeln`, `Reis`, `Nudeln`, `Brot`],
    },
    {
        id: "ILV",
        type: "classic",
        question: `Du wirst wiedergeboren und kannst entscheiden... wohin?`,
        answers: [`In die Zukunft`, `In die Vergangenheit`],
    },
    {
        id: "TXB",
        type: "classic",
        question: `Welche besondere Eigenschaft wäre dir lieber?`,
        answers: [`Gedanken lesen`, `unsichtbar sein`],
    },
    {
        id: "FVE",
        type: "classic",
        question: `Wenn du müsstest, auf was würdest du eher verzichten?`,
        answers: [`auf Filme`, `auf Musik`],
    },
    {
        id: "YSZ",
        type: "classic",
        question: `Wie würdest du lieber wohnen?`,
        answers: [`mit 10 Menschen zusammen`, `lieber allein`],
    },
    {
        id: "GWO",
        type: "classic",
        question: `Würdest du lieber ... ?`,
        answers: [`Nie wieder schwitzen`, `Nie wieder frieren`],
    },
    {
        id: "BSJ",
        type: "classic",
        question: `Für was würdest du dich eher entscheiden?`,
        answers: [`Eine riesige Nase`, `Riesige Ohren`],
    },
    {
        id: "RIE",
        type: "classic",
        question: `Welche Superkraft hättest du gerne?`,
        answers: [`Feuer spucken`, `Röntgenblick`, `Muskelkraft`, `schnell rennen`],
    },
    {
        id: "HFP",
        type: "classic",
        question: `Wenn du nur noch eine Farbe sehen könntest, welche wäre es?`,
        answers: [`Blau`, `Rot`, `Gelb`, `Grün`],
    },
    {
        id: "QRA",
        type: "classic",
        question: `Was denkst du über die Kraft der Gedanken?`,
        answers: [`So ein Schwachsinn`, `Nicht denken... machen!`, `Gedanken kann man nicht kontrollieren`, `Gedanken erschaffen Realität`],
    },
    {
        id: "XAS",
        type: "classic",
        question: `Du hast einen Wunsch frei. In welchen Bereich würdest du diesen einsetzen?`,
        answers: [`Beziehungen`, `Reisen`, `Erlebnisse`, `persönliche Fähigkeiten`],
    },
    {
        id: "RXG",
        type: "classic",
        question: `Dein Haus brennt und du kannst nur noch eine Sache retten. Welche wäre das am ehesten?`,
        answers: [`Ein Buch`, `Kaffeemaschine`, `Schokoriegel`, `Zahnbürste`],
    },
    {
        id: "JVR",
        type: "classic",
        question: `Deine Lebensuhr läuft rückwärts. In welchem Alter möchtest du sie anhalten?`,
        answers: [`bei 30 Jahren`, `bei 25 Jahren`, `bei 18 Jahren`, `bei 10 Jahren`],
    },
    {
        id: "TZW",
        type: "classic",
        question: `Welches Kleidungsstück trägst du am liebsten?`,
        answers: [`T-Shirt`, `Pullover`, `Kleid`, `Hemd`],
    },
    {
        id: "BES",
        type: "classic",
        question: `Welches Smartphone-Betriebssystem bevorzugst du?`,
        answers: [`iOS`, `Android`, `Windows`, `Keine Präferenz`],
    },
    {
        id: "TAW",
        type: "classic",
        question: `Was ist deine bevorzugte Art von Dessert?`,
        answers: [`Schokolade`, `Eis`, `Kuchen`, `Obstsalat`],
    },
    {
        id: "JUS",
        type: "classic",
        question: `Du reist in die Vergangenheit und begegnest deinem jüngeren Ich als Kind. Welchen Rat gibst du?`,
        answers: [`Wird nicht leicht, aber lohnt sich`, `Achte auf deine Beziehungen`, `Bleibe dir treu, egal was passiert`, `Alles wird gut, weitermachen`],
    },
    {
        id: "KVV",
        type: "classic",
        question: `Dein Lieblingsbuch ist ein ... ?`,
        answers: [`Roman`, `Krimi`, `Wissensbuch`, `Kochbuch`],
    },
    {
        id: "UOR",
        type: "classic",
        question: `Du möchtest dein Zimmer neu anstreichen. Du hast nur 3 Grundfarben zur Auswahl. Welche nimmst du?`,
        answers: [`Gelb`, `Rot`, `Grün`],
    },
    {
        id: "LSK",
        type: "classic",
        question: `„Jeder ist seines Glückes Schmied“ Was glaubst du?`,
        answers: [`Ja, stimmt`, `Nein, eher nicht`],
    },
    {
        id: "NPP",
        type: "classic",
        question: `„Wer die Wahrheit sagt, braucht ein schnelles Pferd“ Ist das so?`,
        answers: [`Auf jeden Fall`, `Ach, so ein Quatsch`],
    },
    {
        id: "WQC",
        type: "classic",
        question: `Ein Fremder schenkt dir eine Schachtel und rennt weg. Wie reagierst du?`,
        answers: [`Freust dich und schaust rein`, `Wirfst sie ihm gleich zurück`, `Du gibst sie an andere weiter`],
    },
    {
        id: "RRN",
        type: "classic",
        question: `Welche Art von Büchern liest du am liebsten?`,
        answers: [`Fantasy`, `Krimi/Thriller`, `Romane`, `Sachbücher`],
    },
    {
        id: "PNV",
        type: "classic",
        question: `Welche Jahreszeit magst du am liebsten?`,
        answers: [`Frühling`, `Sommer`, `Herbst`, `Winter`],
    },
    {
        id: "ZLQ",
        type: "classic",
        question: `Welche Art von Film bevorzugst du?`,
        answers: [`Komödie`, `Drama`, `Action`, `Science-Fiction`],
    },
    {
        id: "ZEP",
        type: "classic",
        question: `Du hast die Möglichkeit, eine Sache aus deiner Vergangenheit zu ändern. Was wäre das?`,
        answers: [`eine verpasste Gelegenheit`, `eine falsche Entscheidung`, `ein verpasstes Ereignis`, `ein ungesagtes Wort`],
    },
    {
        id: "SUJ",
        type: "classic",
        question: `Du hast im Lotto gewonnen. Was kaufst du dir als erstes?`,
        answers: [`ein neues Auto`, `ein Haus`, `eine Auslandsreise`, `eine gezielte Wertanlage`],
    },
    {
        id: "LTX",
        type: "classic",
        question: `Wenn Geld keine Rolle spielt, was würdest du am liebsten machen?`,
        answers: [`Arbeit/Tätigkeit ändern`, `nie mehr arbeiten`, `umziehen`, `auswandern`],
    },
    {
        id: "GNQ",
        type: "classic",
        question: `Jemand fragt dich nach einem Treffen. Du hast jedoch wenig Lust. Wie handelst du?`,
        answers: [`eine Ausrede ausdenken`, `ehrlich absagen`, `das Treffen verschieben`, `nicht absagen`],
    },
    {
        id: "UJB",
        type: "classic",
        question: `Nutella-Brot mit Butter oder ohne?`,
        answers: [`Nutella esse ich nicht`, `ganz egal, beides geht`, `Nein, auf keinen Fall`, `Ja, unbedingt`],
    },
    {
        id: "WKV",
        type: "classic",
        question: `Welches Lebensmittel magst du am wengistens?`,
        answers: [`Gurke`, `Tomate`, `Sushi`, `Ananas`],
    },
    {
        id: "XVU",
        type: "classic",
        question: `Welches Lebensmittel magst du am wengistens?`,
        answers: [`Rosenkohl`, `Spargel`, `Pilze`, `Blutwurst`],
    },
    {
        id: "SMV",
        type: "classic",
        question: `Welches Lebensmittel magst du am wengistens?`,
        answers: [`Ziegenkäse`, `Teewurst`, `Oliven`, `Marzipan`],
    },
    {
        id: "TWF",
        type: "classic",
        question: `Welches Lebensmittel magst du am liebsten?`,
        answers: [`Hähnchenfleisch`, `Rindfleisch`, `Tofu`, `Fisch`],
    },
    {
        id: "DAD",
        type: "classic",
        question: `Welches Lebensmittel magst du am liebsten?`,
        answers: [`Popcorn`, `Kekse`, `Chips`, `Schokolade`],
    },
    {
        id: "NJF",
        type: "classic",
        question: `Welches Lebensmittel magst du am liebsten?`,
        answers: [`Brombeeren`, `Heidelbeeren`, `Himbeeren`, `Erdbeeren`],
    },
    {
        id: "LGA",
        type: "classic",
        question: `Der Strom fällt plötzlich aus. Was sind deine ersten Gedanken?`,
        answers: [`Endlich mal Ruhe und nichts geht mehr`, `Irgendwie macht mir das Angst`, `Was soll ich nur ohne Fernsehen und Internet machen?`, `Ist mir egal, mache das Beste daraus`],
    },


    // {
    //     id: "DIQ",
    //     type: "classic",
    //     question: `Welches Haustier passt am ehesten zu dir?`,
    //     answers: [`Hund`, `Katze`, `Fisch`, `Vogel`],
    // },
    // {
    //     id: "TQL",
    //     type: "choice",
    //     question: `Hast du einen dunklen Punkt in deiner Vergangenheit?`,
    // },
    // {
    //     id: "KEJ",
    //     type: "choice",
    //     question: `Sprichst du öfter mit dir selbst, wenn du alleine bist?`,
    // },
    // {
    //     id: "DEY",
    //     type: "choice",
    //     question: `Glaubst du an ein Leben nach dem Tod?`,
    // },
    // {
    //     id: "KZI",
    //     type: "choice",
    //     question: `Hältst du dich für einen guten Verlierer?`,
    // },
    // {
    //     id: "WLG",
    //     type: "choice",
    //     question: `Hast du jemals eine riskante Entscheidung getroffen, die sich als positiv herausgestellt hat?`,
    // },
    // {
    //     id: "FTE",
    //     type: "choice",
    //     question: `Glaubst du daran, das die Seele nach dem Tod weiter existiert?`,
    // },
    // {
    //     id: "XHM",
    //     type: "choice",
    //     question: `Würdest du gerne mal vorübergehend das Geschlecht wechseln?`,
    // },
    // {
    //     id: "NVT",
    //     type: "choice",
    //     question: `Möchtest du unbedingt die Wahrheit wissen, auch wenn sie extrem unangenehm ist?`,
    // },
    // {
    //     id: "YMB",
    //     type: "choice",
    //     question: `Du erhältst eine unerwartete Erbschaft. Ein Bauernhof mit Tieren. Du musst dort aber dann leben. Nimmst du das Erbe an?`,
    // },
    // {
    //     id: "PFV",
    //     type: "choice",
    //     question: `Du erhältst einen Gutschein für 3 Tage in einem Schweigekloster. Würdest du ihn einlösen?`,
    // },
    // {
    //     id: "LOS",
    //     type: "choice",
    //     question: `Du erhältst einen Gutschein für einen Flug ins Weltall. Würdest du ihn einlösen?`,
    // },
    // {
    //     id: "XXU",
    //     type: "choice",
    //     question: `Dir wird ein unendliches Leben geschenkt. Nimmst du es an?`,
    // },
];

export default personal;