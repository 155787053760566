const knowledge = [
    {
        id: "YBR",
        question: `Wie bezeichnet man Wandmalerei auf feuchtem Kalkputz?`,
        answers: [`Fiasko`, `##Fresko`, `Fiesta`, `Frisco`],
    },
    {
        id: "OZI",
        question: `Welche dieser legendären Brücken kann man nicht mit dem Auto passieren?`,
        answers: [`Pont Neuf`, `##Rialtobrücke`, `Golden Gate Bridge`, `Öresundbrücke`],
    },
    {
        id: "KRU",
        question: `Was bedeutet der Name der italienischen Nachspeise „Tiramisu“ wörtlich?`,
        answers: [`süß und köstlich`, `lass dich fallen`, `dicke Kost`, `##zieh mich hoch`],
    },
    {
        id: "FZB",
        question: `Welcher Kinderbuchheld entpuppt sich als Nachfahre von einem der Heiligen Drei Könige?`,
        answers: [`Harry Potter`, `Peter Pan`, `##Jim Knopf`, `Pippi Langstrumpf`],
    },
    {
        id: "OXJ",
        question: `Mit der Drake-Gleichung berechnet man die Wahrscheinlichkeit von ... ?`,
        answers: [`Vierlingsgeburten`, `Erdbeben`, `Lottogewinnen`, `##Außerirdische Intelligenz`],
    },
    {
        id: "UXQ",
        question: `Korrekt schreibt sich der beliebte Cocktail ... ?`,
        answers: [`Caipirnja`, `Cajpirinha`, `##Caipirinha`, `Cajpiriña`],
    },
    {
        id: "AHP",
        question: `Welcher ist der längste Fluss der Welt?`,
        answers: [`Amazonas`, `##Nil`, `Mississippi`, `Jangtse`],
    },
    {
        id: "VMT",
        question: `Wie viele Kontinente gibt es auf der Erde?`,
        answers: [`3`, `6`, `##7`, `5`],
    },
    {
        id: "HCU",
        question: `Wer schrieb das berühmte Werk „Romeo und Julia“?`,
        answers: [`Charles Dickens`, `Jane Austen`, `##William Shakespeare`, `Mark Twain`],
    },
    {
        id: "JWJ",
        question: `Welches Element hat das chemische Symbol „O“?`,
        answers: [`Eisen`, `##Sauerstoff`, `Gold`, `Stickstoff`],
    },
    {
        id: "TGL",
        question: `Ein mediterranes gerudertes Kriegsschiff des Mittelalters und der frühen Neuzeit nennt man ... ?`,
        answers: [`Kogge`, `Schaluppe`, `##Galeere`, `Draisine`],
    },
    {
        id: "LYW",
        question: `Wobei wird vor einem sogenannten Rebound-Effekt gewarnt, der nicht selten zu einer Abhängigkeit führt?`,
        answers: [`Haarspray`, `Deospray`, `##Nasenspray`, `Pfefferspray`],
    },
    {
        id: "OTM",
        question: `Die klassische, genormte Europalette EPAL 1 besteht aus 78 Nägeln, 9 Klötzen und insgesamt wie vielen Brettern?`,
        answers: [`9`, `10`, `##11`, `12`],
    },
    {
        id: "YFS",
        question: `Ein Zorilla ist ein ... ?`,
        answers: [`Vogel`, `Halbaffe`, `##Marder`, `Menschenaffe`],
    },
    {
        id: "NPY",
        question: `Was sind grau, schwarz und weiß in der Farbenlehre?`,
        answers: [`Farblos`, `##Unbunt`, `Reinbunt`, `Abstrakt`],
    },
    {
        id: "FHQ",
        question: `Welcher Begriff bezieht sich auf Wechselstrom?`,
        answers: [`Courtage`, `##Effektivwert`, `Kabotage`, `Effektivzins`],
    },
    {
        id: "TWZ",
        question: `Wer oder was ist Pelota?`,
        answers: [`Ein Berg in Kolumbien`, `Ein spanischer Schriftsteller`, `##Eine baskische Sportart`, `Ein erfolgreicher Stierkämpfer`],
    },
    {
        id: "GEH",
        question: `Welche Erfindung geht auf Edmund Cartwright zurück?`,
        answers: [`Die Schreibmaschine`, `Der Fallschirm`, `##Der mechanische Webstuhl`, `Der Haarföhn`],
    },
    {
        id: "RSL",
        question: `Raps und Senf sind ... ?`,
        answers: [`Korbblütengewächse`, `Liliengewächse`, `Knöterichgewächse`, `##Kreuzblütengewächse`],
    },
    {
        id: "CNB",
        question: `Was ist ein Leichtmetall?`,
        answers: [`##Aluminium`, `Chrom`, `Eisen`, `Kupfer`],
    },
    {
        id: "PNG",
        question: `Wer an Agoraphobie leidet, fürchtet ... ?`,
        answers: [`Tiere`, `##freie Plätze`, `Geschlossene Räume`, `Donner`],
    },
    {
        id: "IPZ",
        question: `Wie heißen aus botanischer Sicht die Früchte, die an einer Erdbeerstaude wachsen?`,
        answers: [`Beere`, `##Nüsschen`, `Balg`, `Schote`],
    },
    {
        id: "OVA",
        question: `Welches der folgenden Tiere ist ein Insekt?`,
        answers: [`Spinne`, `Tausendfüßler`, `##Ameise`, `Skorpion`],
    },
    {
        id: "YVO",
        question: `Welche Sprache gehört zu den semitischen Sprachen?`,
        answers: [`Türkisch`, `Keltisch`, `Georgisch`, `##Arabisch`],
    },
    {
        id: "FKX",
        question: `Welche chemische Verbindung macht den Großteil der Luft aus?`,
        answers: [`##Stickstoff`, `Kohlendioxid`, `Sauerstoff`, `Methan`],
    },
    {
        id: "JHN",
        question: `Was sind Anchovis?`,
        answers: [`##Fische`, `Blumen`, `Früchte`, `Vögel`],
    },
    {
        id: "VTQ",
        question: `Was dient als Indikator für Luftverschmutzungen?`,
        answers: [`Feldulmen`, `##Flechten`, `Agaven`, `Pilze`],
    },
    {
        id: "NLF",
        question: `Wie nennt man den Kälteeinbruch im Juni, der in Mitteleuropa auftritt?`,
        answers: [`Bärenkälte`, `Fuchskälte`, `##Schafskälte`, `Lammkälte`],
    },
    {
        id: "CFT",
        question: `Was ist ein Sabbatjahr?`,
        answers: [`Ein besonders ertragreiches Jahr`, `Ein besonders verlustreiches Jahr`, `Ein Trauerjahr`, `##Ein Jahr der beruflichen Freistellung`],
    },
    {
        id: "AWM",
        question: `Welche Lied- und Tanzform entstand im 19. Jahrhundert in der Karibik?`,
        answers: [`Jitterbug`, `Salsa`, `##Merengue`, `Mambo`],
    },
    {
        id: "ECH",
        question: `Wo befinden sich unter anderem die Papillarleisten?`,
        answers: [`Kniekehle`, `Oberarm`, `##Handinnenseite`, `Bauch`],
    },
    {
        id: "UAB",
        question: `Was ist ein Blaufelchen?`,
        answers: [`Vogel`, `Schmetterling`, `##Fisch`, `Käfer`],
    },
    {
        id: "ZXF",
        question: `Was ändert sich, wenn man im Pazifischen Ozean den 180. Längengrad passiert?`,
        answers: [`##Datum`, `Windrichtung`, `Luftfeuchte`, `Magnetpol`],
    },
    {
        id: "VIJ",
        question: `Was bedeutet der türkische Frauenname Bahar?`,
        answers: [`##Frühling`, `Sommer`, `Herbst`, `Winter`],
    },
    {
        id: "PQT",
        question: `Kelvin ist die Einheit der ... ?`,
        answers: [`##thermodynamischen Temperatur`, `Wärmekapazität`, `Wärmeleitfähigkeit`, `Kapazität eines Kondensators`],
    },
    {
        id: "YQT",
        question: `Was ist ein Glaukom?`,
        answers: [`Grauer Star`, `##Grüner Star`, `Altersweitsichtigkeit`, `Hornhautverkrümmung`],
    },
    {
        id: "NCC",
        question: `Wie viel Prozent der Weltbevölkerung sind Linkshänder?`,
        answers: [`##ca. 10%`, `ca. 20%`, `ca. 30%`, `ca. 40%`],
    },
    {
        id: "TOL",
        question: `Welche Quiz-Sendung moderierte Wim Thoelke?`,
        answers: [`Um keinen Preis`, `Ohne Fleiß kein Preis`, `Der Preis ist heiß`, `##Der große Preis`],
    },
    {
        id: "IYW",
        question: `Welcher dieser Einheiten hat die kleinste Länge?`,
        answers: [`Seemeile`, `Parsec`, `##Britische Meile`, `Lichtjahr`],
    },
    {
        id: "XXN",
        question: `Wie wird im Bankwesen die Haftung für Verbindlichkeiten genannt?`,
        answers: [`Vertigo`, `Latigo`, `##Obligo`, `Indigo`],
    },
    {
        id: "DQC",
        question: `Welches Land baute das erste Atomkraftwerk?`,
        answers: [`USA`, `##UdSSR`, `Israel`, `Australien`],
    },
    {
        id: "JKC",
        question: `Welches Tier fällt in einen Winterschlaf?`,
        answers: [`##Bär`, `Eichhörnchen`, `Eidechse`, `Amsel`],
    },
    {
        id: "CGL",
        question: `Plomben als Siegel für Behälter oder Gehäuse bestehen dem Wortursprung nach aus ... ?`,
        answers: [`Zinn`, `##Blei`, `Silber`, `Lack`],
    },
    {
        id: "VGT",
        question: `Wer spielte in dem Film „Das Schweigen der Lämmer“ die Rolle der FBI-Agentin Clarice Starling?`,
        answers: [`Julia Roberts`, `##Jodie Foster`, `Jamie Lee Curtis`, `Jessica Tandy`],
    },
    {
        id: "UOK",
        question: `Was ist kein Fleischgericht?`,
        answers: [`##Palatschinken`, `Poulet`, `Kotelett`, `Kassler`],
    },
    {
        id: "WBT",
        question: `Welcher berühmte Physiker formulierte die Relativitätstheorie?`,
        answers: [`Isaac Newton`, `##Albert Einstein`, `Niels Bohr`, `Galileo Galilei`],
    },
    {
        id: "BYJ",
        question: `Wie nennt man einen landwirtschaftlichen Betrieb, der sich auf die Pferdezucht spezialisiert hat?`,
        answers: [`Gepferd`, `Gehengst`, `Gefohlen`, `##Gestüt`],
    },
    {
        id: "KLB",
        question: `Wer unter einem „Hallux valgus“ leidet, hat wo Beschwerden?`,
        answers: [`Nase`, `Halswirbel`, `Augen`, `##Zeh`],
    },
    {
        id: "CYA",
        question: `Ein anderer Begriff für „Liebstöckel“ ist ... ?`,
        answers: [`Bohnenkraut`, `Currykraut`, `Pfefferkraut`, `##Maggikraut`],
    },
    {
        id: "CUV",
        question: `Ein anderes Wort für „Zusammenwirken“ ist?`,
        answers: [`Synesis`, `##Synergie`, `Synkarpie`, `Synechie`],
    },
    {
        id: "ENO",
        question: `Welche Tiere töten den größten Teil ihrer Nachkommen?`,
        answers: [`Schmetterlinge`, `##Taranteln`, `Flöhe`, `Schlangen`],
    },
    {
        id: "RCI",
        question: `Wie nennt man einen Verweis auf einer Website zu einer anderen?`,
        answers: [`Superlink`, `Connectionlink`, `##Hyperlink`, `Microlink`],
    },
    {
        id: "ZNY",
        question: `Bei welcher olympischen Disziplin gibt es keine Trennung nach Geschlechtern?`,
        answers: [`Fechten`, `##Springreiten`, `Volleyball`, `Judo`],
    },
    {
        id: "VEV",
        question: `Wie heißt ein dreidimensionales Abbildungsverfahren?`,
        answers: [`##Holographie`, `Monographi`, `Xerographie`, `Lithographie`],
    },
    {
        id: "NYP",
        question: `Was bedeutet das polnische „na zdrowie“?`,
        answers: [`Guten Tag`, `##Zum Wohl`, `Guten Appetit`, `Lass dir schmecken`],
    },
    {
        id: "PPE",
        question: `Welches Wort beschreibt einen Verwandtschaftsgrad?`,
        answers: [`Lauge`, `##Base`, `Säure`, `Salz`],
    },
    {
        id: "QID",
        question: `Welches Tier ist kein Hund?`,
        answers: [`##Präriehund`, `Cockerspaniel`, `irischer Wolfshund`, `Pekinese`],
    },
    {
        id: "ZTS",
        question: `Wie werden Modekreationen renommierter Modehäuser im obersten Preissegment bezeichnet?`,
        answers: [`##Haute Couture`, `Hot design`, `Imperméable`, `Fashion Hero`],
    },
    {
        id: "GAY",
        question: `Was wird durch BSE zerstört?`,
        answers: [`Lunge`, `Herz`, `Knochen`, `##Gehirn`],
    },
    {
        id: "NRJ",
        question: `Von welchem Planeten kommt Alf?`,
        answers: [`Almack`, `Plomack`, `##Melmack`, `Solmack`],
    },
    {
        id: "YCD",
        question: `Wie heißt die Bühne der Zirkuskünstler?`,
        answers: [`Ollie`, `Claudel`, `##Manege`, `Bonheur`],
    },
    {
        id: "JBB",
        question: `Was ist Heraldik?`,
        answers: [`Waffenkunde`, `Briefmarkenkunde`, `##Wappenkunde`, `Völkerkunde`],
    },
    {
        id: "CBJ",
        question: `Wie bezeichnet man eine dekorative Einlegearbeit aus unterschiedlichen Furnierhölzern?`,
        answers: [`##Intarsie`, `Textur`, `Versalie`, `Kalligraph`],
    },
    {
        id: "WMF",
        question: `Womit flüchteten am 16. September 1979 zwei Familien aus der DDR nach Westdeutschland?`,
        answers: [`##Heißluftballon`, `U-Boot`, `Segelflugzeug`, `Helikopter`],
    },
    {
        id: "NME",
        question: `„Melange“ bedeutet so viel wie ... ?`,
        answers: [`Komplott`, `Einigkeit`, `Komposition`, `##Mischung`],
    },
    {
        id: "BMR",
        question: `Was ist in der Mitte des „Bundesverdienstkreuzes“ zu sehen?`,
        answers: [`Deutsche Nationalflagge`, `Deutsche D-Mark`, `Deutsche Nationalhymne`, `##Deutscher Bundesadler`],
    },
    {
        id: "QNC",
        question: `Worum ging es in der ARD Sendung „Der 7. Sinn“?`,
        answers: [`Sportregeln`, `Kochregeln`, `Handwerkerregeln`, `##Verkehrsregeln`],
    },
    {
        id: "VNP",
        question: `Wie heißt eine alte Kultur-Apfelsorte?`,
        answers: [`Baronsteiner`, `##Gravensteiner`, `Fürstensteiner`, `Königssteiner`],
    },
    {
        id: "KBT",
        question: `Wie nennt man einen Wandteppich mit kunstvollen Stickereien, die Muster oder Bildszenen darstellen?`,
        answers: [`Gabione`, `Goblin`, `##Gobelin`, `Gauguin`],
    },
    {
        id: "FRR",
        question: `Wie heißt die wichtigste polizeiliche Sondereinheit in Österreich?`,
        answers: [`Kranich`, `Eagle`, `##Cobra`, `Tiger`],
    },
    {
        id: "SNK",
        question: `Aus welchem Land stammt der „Stilton-Käse“?`,
        answers: [`Österreich`, `Frankreich`, `##England`, `Italien`],
    },
    {
        id: "FBI",
        question: `Wie heißt der Damm, der Sylt mit dem Festland verbindet?`,
        answers: [`Zusedamm`, `Heinrichdamm`, `##Hindenburgdamm`, `Gustavdamm`],
    },
    {
        id: "UXK",
        question: `Was ist „Tarock“?`,
        answers: [`##Kartenspiel`, `Baustil`, `Kleidungsstück`, `Insekt`],
    },
    {
        id: "BDK",
        question: `Welcher Begriff der Naturwissenschaften beschreibt die Dauerbeobachtung eines bestimmten Systems?`,
        answers: [`Screening`, `##Monitoring`, `Seeing`, `Checking`],
    },
    {
        id: "MMO",
        question: `In welcher Einheit wird die (absolute) Temperatur gemessen?`,
        answers: [`Fahrenheit (°F)`, `Farad (F)`, `Celsius (°C)`, `##Kelvin (K)`],
    },
    {
        id: "LAT",
        question: `Wie nennt man ein schwimmendes Seezeichen, das der Orientierung auch bei Nebel dient?`,
        answers: [`Wellentonne`, `Schwimmtonne`, `Sichttonne`, `##Heultonne`],
    },
    {
        id: "BDQ",
        question: `Ist etwas enigmatisch, so ist es ... ?`,
        answers: [`blutarm`, `schreckhaft`, `##rätselhaft`, `vorzeitlich`],
    },
    {
        id: "AKO",
        question: `Was kann spätere Wahrnehmungen überlagern und sich als Beurteilungsfehler auswirken?`,
        answers: [`erste Visualisierung`, `erste Konsequenz`, `erste Nachwirkung`, `##erste Eindruck`],
    },
    {
        id: "UEB",
        question: `Bei welcher Sportart ist der „Rittberger“ ein fester Begriff?`,
        answers: [`##Eiskunstlauf`, `Kugelstoßen`, `Schwimmen`, `Rudern`],
    },
    {
        id: "BPF",
        question: `Was kann man bei Säuglingen am Kopf sehen?`,
        answers: [`##Fontanelle`, `Fondant`, `Fontana di Trevi`, `Fontainebleau`],
    },
    {
        id: "XNY",
        question: `Welches Ausweichmanöver simuliert ein plötzlich auftretendes Hindernis auf der Straße?`,
        answers: [`##Elchtest`, `Rehtest`, `Hirschtest`, `Wildtest`],
    },
    {
        id: "TLM",
        question: `Was kann man mit einem „Gaumenpfeiferl“ nachahmen?`,
        answers: [`Mickymausstimme`, `Orgelspiel`, `Fledermausstimme`, `##Vogelgezwitscher`],
    },
    {
        id: "EYM",
        question: `Basis der betrieblichen Berufsausbildung in Deutschland ist ... ?`,
        answers: [`das singuläre Prinzip`, `die dreifache Lernweise`, `##das duale System`, `das Zucker-Peitsche-Motto`],
    },
    {
        id: "YQV",
        question: `Wer erschlug „Sieben auf einen Streich“?`,
        answers: [`##das tapfere Schneiderlein`, `Hänsel`, `Gretel`, `der Froschkönig`],
    },
    {
        id: "SSR",
        question: `Kalligraphie ist die Kunst des schönen ... ?`,
        answers: [`Papierfaltens`, `##Schreibens`, `Schminkens`, `Tätowierens`],
    },
    {
        id: "ORM",
        question: `Wie wird ein Kellner bezeichnet, der in Köln, Bonn, Düsseldorf oder Krefeld Bier serviert?`,
        answers: [`Kantor`, `Kommod`, `Küfer`, `##Köbes`],
    },
    {
        id: "JZB",
        question: `Welche dieser Pflanzen gibt es wirklich?`,
        answers: [`##Mönchspfeffer`, `Pastorenkresse`, `Papstpaprika`, `Diakonenmajoran`],
    },
    {
        id: "ITZ",
        question: `Welche Tiere leben im NRW-Naturschutzgebiet „Zwillbrocker Venn“ in freier Wildbahn?`,
        answers: [`Piranhas`, `Alligatoren`, `Nilpferde`, `##Flamingos`],
    },
    {
        id: "KTK",
        question: `Was ist eine Anlage zur Abgasnachbehandlung in Fahrzeugen mit Verbrennungsmotor?`,
        answers: [`Auspuff`, `##Katalysator`, `Abgaskrümmer`, `Mittelschalldämpfer`],
    },
    {
        id: "VWX",
        question: `Wie nennt man ein Verfahren zur Rettung eines Bergsteigers aus einer Gletscherspalte?`,
        answers: [`Mailänder Flaschenzug`, `Schwedischer Flaschenzug`, `##Schweizer Flaschenzug`, `Deutscher Flaschenzug`],
    },
    {
        id: "ODD",
        question: `Wobei handelt es sich um ein Wildgemüse?`,
        answers: [`Exzellenter Egon`, `Sauberer Siegfried`, `Feiner Fritz`, `##Guter Heinrich`],
    },
    {
        id: "EXS",
        question: `Was ist ein Seiher?`,
        answers: [`Trinkflasche`, `##Abtropfsieb`, `Wasservogel`, `Mauervorsprung`],
    },
    {
        id: "YSL",
        question: `Wie heißt der Amtsring des Papstes?`,
        answers: [`Tischlerring`, `Bäckerring`, `##Fischerring`, `Sängerring`],
    },
    {
        id: "VTK",
        question: `Wo befindet sich das „Miniatur Wunderland“, die größte Modelleisenbahnanlage der Welt?`,
        answers: [`##Hamburg`, `Paris`, `Mailand`, `Oslo`],
    },
    {
        id: "JQV",
        question: `Die Entsorgung von Abfällen in Gewässer nennt man ... ?`,
        answers: [`Verödung`, `Vermehrung`, `##Verklappung`, `Verpuffung`],
    },
    {
        id: "CVK",
        question: `Bei einem Rechtsstreit ist ein Verfahren bei einem Gericht ... ?`,
        answers: [`##anhängig`, `anhaftend`, `anklebend`, `anhaltend`],
    },
    {
        id: "OBL",
        question: `Wer ist deutscher Synchronsprecher des Riesenfaultiers Sid im Film „Ice Age“?`,
        answers: [`Christoph Maria Herbst`, `##Otto Waalkes`, `Bastian Pastewka`, `Michael Mittermeier`],
    },
    {
        id: "QRB",
        question: `Welche Trickfigur ist keine Katze bzw. kein Kater?`,
        answers: [`Garfield`, `Azrael`, `##Shaun`, `Sylvester`],
    },
    {
        id: "KUG",
        question: `Was ist „Bruxismus“?`,
        answers: [`Stirnrunzeln`, `##Zähneknirschen`, `Augenrollen`, `Ohrensausen`],
    },
    {
        id: "FWS",
        question: `Wie viele Eier legen Haushühner (Legerassen) etwa in einem Jahr?`,
        answers: [`50-150`, `100-200`, `##250-300`, `20-100`],
    },
    {
        id: "TLX",
        question: `In welchen Monaten wurde man geboren, wenn man das Sternzeichen „Löwe“ hat?`,
        answers: [`Januar / Februar`, `##Juli / August`, `November / Dezember`, `September / Oktober`],
    },
    {
        id: "TMF",
        question: `Wer Tugendhaftigkeit fordert, der schwingt gerne mal die ... ?`,
        answers: [`Moralkelle`, `Moralstrippe`, `Moralpeitsche`, `##Moralkeule`],
    },
    {
        id: "DET",
        question: `	Wie nennt man den Ersatz für etwas oder jemanden in Ermangelung einer besseren Alternative?`,
        answers: [`##Notnagel`, `Zwickmühle`, `Zwangslage`, `Kreuzschraube`],
    },
    {
        id: "YTD",
        question: `Wie nennt man ein Abkommen, dass es zu keinerlei gewaltsamen Auseinandersetzungen kommen darf?`,
        answers: [`Landeintracht`, `##Burgfrieden`, `Schlossruhe`, `Stadtharmonie`],
    },
    {
        id: "AQC",
        question: `Wie heißt eine österreichische „Tierschutzorganisation“ in Wien?`,
        answers: [`##Vier Pfoten`, `Vier Flossen`, `Vier Tatzen`, `Vier Flügel`],
    },
    {
        id: "UUA",
        question: `Eine Einrichtung zur Verbesserung der Gesundheit chronisch Kranker ist ein ... ?`,
        answers: [`Sudatorium`, `Sensorium`, `##Sanatorium`, `Senatorium`],
    },
    {
        id: "VKL",
        question: `Was ist die Quadratwurzel der Zahl 144?`,
        answers: [`8`, `16`, `##12`, `14`],
    },
    {
        id: "QBC",
        question: `Wer mit jemandem keinerlei Interessen oder Eigenschaften teilt, hat mit ihm nichts ... ?`,
        answers: [`ekelhaft`, `##gemein`, `fies`, `widerlich`],
    },
    {
        id: "XNT",
        question: `Stößt man auf das Kürzel „LotR“, ist in der Regel was das Thema?`,
        answers: [`Fluch der Karibik`, `Harry Potter`, `Krieg der Sterne`, `##Der Herr der Ringe`],
    },
    {
        id: "YXN",
        question: `Wer jemanden gezielt verleumdet, begeht ... ?`,
        answers: [`Sprechdiebstahl`, `##Rufmord`, `Kreischklau`, `Brüllraub`],
    },
    {
        id: "MPQ",
        question: `Was ist eine „Bresche“?`,
        answers: [`großer Teigkessel`, `Bach am Ackerrand`, `zerrissene Stoffstelle`, `##Festungsmauerlücke`],
    },
    {
        id: "YGH",
        question: `Welcher Staat hat ein dreiblättriges Kleeblatt als inoffizielles Nationalsymbol?`,
        answers: [`##Irland`, `Schweden`, `Belgien`, `Niederlande`],
    },
    {
        id: "DNL",
        question: `Wie nennt man im Straßenwesen eine enge U- bzw. V-förmige Kurve mit einem Winkel von 150° bis 180°?`,
        answers: [`Stricknadelkurve`, `Nähnadelkurve`, `##Haarnadelkurve`, `Bartnadelkurve`],
    },
    {
        id: "FSI",
        question: `Wie lautet der Markenname des ältesten deutschen Weinbrands?`,
        answers: [`Conde de Osborne Dalí`, `##Asbach Uralt`, `Rüdesheimer Kirschwasser`, `Rémy Martin`],
    },
    {
        id: "JLL",
        question: `Wie wird das Apnoetauchen auch genannt?`,
        answers: [`Bloßtauchen`, `##Freitauchen`, `Blanktauchen`, `Reintauchen`],
    },
    {
        id: "EIE",
        question: `Hat ein Franzose „Baccalauréat“, dann hat er ..?`,
        answers: [`einen Master-Abschluss`, `goldene Hochzeit`, `den Führerschein`, `##das Abitur`],
    },
    {
        id: "BCC",
        question: `In welchem Teilgebiet der Physik wird mit dem „Ohmschen Gesetz“ hantiert?`,
        answers: [`##Elektrizität`, `Mechanik`, `Optik`, `Akustik`],
    },
    {
        id: "UXI",
        question: `Wie wird der Hauptturm einer mittelalterlichen Burg genannt?`,
        answers: [`Turmfried`, `Siegfried`, `##Bergfried`, `Torfried`],
    },
    {
        id: "WBI",
        question: `Wie wird ein roter Hautfleck am Hinterkopf eines Säuglings bezeichnet?`,
        answers: [`##Storchenbiss`, `Bienenstich`, `Teufelskralle`, `Pferdekuss`],
    },
    {
        id: "LPJ",
        question: `Wie wird eine Stachelwalze, die das Ablösen von Tapeten erleichtert, auch genannt?`,
        answers: [`Büffelwalze`, `##Igelwalze`, `Amselwalze`, `Eselwalze`],
    },
    {
        id: "BTL",
        question: `Mit welchem Gerät lockert der Landwirt die Bodenoberfläche auf?`,
        answers: [`Schwader`, `##Egge`, `Walze`, `Forwarder`],
    },
    {
        id: "FUI",
        question: `Wer grüßt mit „Mast- und Schotbruch“?`,
        answers: [`Jäger`, `##Segler`, `Luftschiffer`, `Bergleute`],
    },
    {
        id: "TZF",
        question: `Eine Zeitung, der nur eingeschränkte Seriosität zugeschrieben wird, nennt man ... ?`,
        answers: [`Green Press`, `Black Press`, `Red Press`, `##Yellow Press`],
    },
    {
        id: "YAE",
        question: `Welches Tier hat die meisten Zähne?`,
        answers: [`##Weißer Hai`, `Nilpferd`, `Blauwal`, `Ameisenbär`],
    },
    {
        id: "QJH",
        question: `Welcher dieser Filme wurde komplett in einem einzigen Take gedreht?`,
        answers: [`Birdman`, `##1917`, `Russian Ark`, `Rope`],
    },
    {
        id: "JSA",
        question: `Das Asperger-Syndrom ist eine Variante des ... ?`,
        answers: [`##Autismus`, `Bruxismus`, `Marasmus`, `Nihilismus`],
    },
    {
        id: "ULM",
        question: `Wie heißt ein historisches Möbel mit einer Schreibfläche?`,
        answers: [`Schreibkraft`, `Schriftführer`, `Protokollant`, `##Sekretär`],
    },
    {
        id: "UFO",
        question: `Was ist eine „Sepsis“?`,
        answers: [`Herzschwäche`, `Leberschwäche`, `Luftnot`, `##Blutvergiftung`],
    },
    {
        id: "CBN",
        question: `Welcher Soul- und Pop-Sänger ist seit Geburt blind?`,
        answers: [`John Legend`, `##Stevie Wonder`, `Lionel Richie`, `Freddie Jackson`],
    },
    {
        id: "ZUQ",
        question: `Welches Tier heißt im wissenschaftlichen Sprachgebrauch auch „Meduse“ oder „Medusa“?`,
        answers: [`Wal`, `Fischotter`, `##Qualle`, `Krokodil`],
    },
    {
        id: "VVP",
        question: `Aus welchen beiden farblich aufeinander abgestimmten Kleidungsstücken besteht ein „Twinset“?`,
        answers: [`##Pullover und Strickjacke`, `Schuhe und Strümpfe`, `Bluse und Gürtel`, `Schal und Wollmütze`],
    },
    {
        id: "PSR",
        question: `Ist etwas sehr betont und auffällig, dann nennt man es auch ... ?`,
        answers: [`spektiv`, `adaptiv`, `##plakativ`, `fixativ`],
    },
    {
        id: "USJ",
        question: `Wie wird der Beiwagen bei einem Motorradgespann auch bezeichnet?`,
        answers: [`Zopf`, `##Boot`, `Dach`, `Kran`],
    },
    {
        id: "ORI",
        question: `Welche Insekten zählen nicht zu den sozialen bzw. staatenbildenden Arten?`,
        answers: [`Termiten`, `Ameisen`, `Bienen`, `##Heuschrecken`],
    },
    {
        id: "OJU",
        question: `Wie viele Kinder sind auf dem Gefahrzeichen, das Autofahrer vor Kindern warnt, abgebildet?`,
        answers: [`Eins`, `##Zwei`, `Drei`, `je nach Bundesland`],
    },
    {
        id: "KVD",
        question: `Eine „B&B-Unterkunft“ bietet dem Namen nach „Bed and ...“?`,
        answers: [`Beer`, `##Breakfast`, `Bath`, `Beverages`],
    },
    {
        id: "IFM",
        question: `Woraus werden Rosinen gewonnen?`,
        answers: [`Stachelbeeren`, `##Weinbeeren`, `Vogelbeeren`, `Johannisbeeren`],
    },
    {
        id: "EKQ",
        question: `Womit beschäftigt sich der Onkologe?`,
        answers: [`##Tumorerkrankungen`, `Erziehungsfragen`, `Kaffee`, `Keksherstellung`],
    },
    {
        id: "PMF",
        question: `Die „Golden Gate Bridge“ wurde konstruiert als ... ?`,
        answers: [`Betonbrücke`, `##Hängebrücke`, `Steinbogenbrücken`, `Balkenbrücke`],
    },
    {
        id: "XJH",
        question: `Engstirnig, in ärgerlicher Weise eingebildet und auf seinen Vorstellungen beharrend, nennt man ... ?`,
        answers: [`##borniert`, `bonitiert`, `bougiert`, `bombiert`],
    },
    {
        id: "YXJ",
        question: `Welcher Begriff steht nicht für eine „hohe Geschwindigkeit“?`,
        answers: [`geölter Blitz`, `##Strohfeuer`, `Affenzahn`, `volle Pulle`],
    },
    {
        id: "YIS",
        question: `Das Verkehrszeichen für das Ende einer Kraftfahrstraße wird wie dargestellt?`,
        answers: [`##blau mit roten Schrägstrich`, `grau mit 5 schwarzen Schrägstrichen`, `blau mit 5 schwarzen Schrägstrichen`, `grau mit roten Schrägstrich`],
    },
    {
        id: "GZQ",
        question: `Wie wird eine witterungsbedingte Abtragung von Gestein in der Geologie bezeichnet?`,
        answers: [`Fusion`, `Destination`, `Eruption`, `##Erosion`],
    },
    {
        id: "QAI",
        question: `Eine traditionelle Süßspeise ist der „Apfel im ...“?`,
        answers: [`##Schlafrock`, `Mantel`, `Anzug`, `Pyjama`],
    },
    {
        id: "TSH",
        question: `Welche Frucht ist ein Hybrid zwischen Mandarine und Orange?`,
        answers: [`##Clementine`, `Zitrone`, `Bitterorange`, `Limette`],
    },
    {
        id: "DAO",
        question: `Beim Fußball versuchen die Kicker oftmals einen Spielzug über die ... ?`,
        answers: [`Schnäbel`, `Krallen`, `Federn`, `##Flügel`],
    },
    {
        id: "TMQ",
        question: `Wie wird ein erwachsener männlicher Gorilla etwa ab dem 12. Lebensjahr bezeichnet?`,
        answers: [`Bronzekopf`, `##Silberrücken`, `Platinbrust`, `Goldschulter`],
    },
    {
        id: "YAX",
        question: `Wie heißt das traditionelle Bierglas, aus dem Pils getrunken wird?`,
        answers: [`Pilsrose`, `Pilsnelke`, `##Pilstulpe`, `Pilsprimel`],
    },
    {
        id: "GZZ",
        question: `Wer arbeitet „undercover“?`,
        answers: [`G-Mann`, `A-Mann`, `X-Mann`, `##V-Mann`],
    },
    {
        id: "PYM",
        question: `Als Adiposität bezeichnet man die ... ?`,
        answers: [`Alkoholsucht`, `Magersucht`, `Herzschwäche`, `##Fettleibigkeit`],
    },
    {
        id: "XSQ",
        question: `Wer führte bei dem Science-Fiction-Film „Avatar“ Regie?`,
        answers: [`Tim Burton`, `##James Cameron`, `Uli Edel`, `Michael Bay`],
    },
    {
        id: "WED",
        question: `Wird Puderzucker mit Oxidationsmitteln vermischt, ergibt das eine ... ?`,
        answers: [`##explosionsfähige Mischung`, `kalorienarme Süßigkeit`, `aromatische Kaffeezutat`, `bekömmliche Nachspeise`],
    },
    {
        id: "AWU",
        question: `Was ist kein Teilgebiet der Biologie?`,
        answers: [`Botanik`, `Genetik`, `##Ökonomie`, `Zoologie`],
    },
    {
        id: "RIO",
        question: `In der Musik ist der G-Schlüssel besser bekannt als ... ?`,
        answers: [`Diskantschlüssel`, `Tenorschlüssel`, `Bassschlüssel`, `##Violinschlüssel`],
    },
    {
        id: "FLJ",
        question: `Mit welcher Single starteten die „No Angels“ im Februar 2001 ihre Karriere?`,
        answers: [`##Daylight in Your Eyes`, `Maybe`, `Like Eyes in the Sunshine`, `Goodbye to Yesterday`],
    },
    {
        id: "WEW",
        question: `Wo breitet sich der Schall nicht aus?`,
        answers: [`im Wasser`, `in Metallen`, `##im Vakuum`, `im Holz`],
    },
    {
        id: "FKZ",
        question: `Der Pfeil, der den Anfang eines Parkverbots auf dem Verkehrszeichen angibt, zeigt immer ... ?`,
        answers: [`nach links`, `nach rechts`, `##zur Fahrbahn`, `weg von der Fahrbahn`],
    },
    {
        id: "RPP",
        question: `In welcher Sportart ist der K-Punkt von Bedeutung?`,
        answers: [`Eishockey`, `##Skispringen`, `Biathlon`, `Eisschnelllauf`],
    },
    {
        id: "EYL",
        question: `„Meal Prep“ ist das neudeutsche Modewort für das gute, alte ... ?`,
        answers: [`Kartoffelpürree`, `Gemüsebeet`, `##Vorkochen`, `Picknick`],
    },
    {
        id: "NDX",
        question: `Wovon jemand profitieren soll, das lässt man ihm ... ?`,
        answers: [`##zugutekommen`, `zugute kommen`, `zu gute kommen`, `zu Gute kommen`],
    },
    {
        id: "NQM",
        question: `Der Begriff „Endlosrille“ bezeichnet eine Technik in der ... ?`,
        answers: [`Humanmedizin`, `##Schallplattenpressung`, `Lotterie`, `Architektur`],
    },
    {
        id: "XBB",
        question: `Welchen dieser Berufe gibt es wirklich?`,
        answers: [`Abnehmpartner`, `##Diätassistent`, `Fastengehilfe`, `Hungersekretär`],
    },
    {
        id: "OIJ",
        question: `Welches ist ein bekanntes Gymnastik- und Geschicklichkeitsspiel?`,
        answers: [`##Schubkarre`, `Rasenmäher`, `Gartenlaube`, `Holzhacker`],
    },
    {
        id: "RJA",
        question: `Beim allabendlichen Sandmännchen gibt es Pitti, Schnatterinchen und ... ?`,
        answers: [`##Moppi`, `Samson`, `Lassie`, `Idefix`],
    },
    {
        id: "QBQ",
        question: `Welches Tier gibt es wirklich?`,
        answers: [`Rufelch`, `Laberaal`, `##Schreiadler`, `Brüllbiber`],
    },
    {
        id: "NLR",
        question: `Was enthält Asphalt?`,
        answers: [`Ritumen`, `Leumen`, `##Bitumen`, `Litumen`],
    },
    {
        id: "QPF",
        question: `Woraus wird Biodiesel gewonnen?`,
        answers: [`##Raps`, `Gerste`, `Narzisse`, `Ginster`],
    },
    {
        id: "DAR",
        question: `Welches Element ist erfunden?`,
        answers: [`Bohrium`, `Fermium`, `Nobelium`, `##Fasium`],
    },
    {
        id: "IRM",
        question: `Die Wirbelstürme in Südost-Asien heißen?`,
        answers: [`Taipeh`, `Torpedo`, `##Taifun`, `Tornado`],
    },
    {
        id: "VST",
        question: `Welches Getränk wird umgangssprachlich als „Bölkstoff“ bezeichnet?`,
        answers: [`##Bier`, `Wein`, `Orangensaft`, `Champagner`],
    },
    {
        id: "DIM",
        question: `Die Nachahmung wehrhafter Tiere durch Form und Farbanpassung nennt man ... ?`,
        answers: [`Mimose`, `Mime`, `Mimik`, `##Mimikry`],
    },
    {
        id: "AXQ",
        question: `Der veraltete Ausdruck für Waschschüssel ist?`,
        answers: [`Bidet`, `##Lavoir`, `Bede`, `Lavant`],
    },
    {
        id: "NZZ",
        question: `Wofür steht der Begriff „Schelte“?`,
        answers: [`Ignoranz`, `Lob`, `##Tadel`, `Bewunderung`],
    },
    {
        id: "DEM",
        question: `Was ist ein Kaftan?`,
        answers: [`##Der schwarze Mantel der Juden`, `Das Gewand eines Scheichs`, `Die Kopfbedeckung indischer Einwohner`, `Das Oberteil südländischer Bewohner`],
    },
    {
        id: "TPJ",
        question: `Welchen Namen hat die französische Fahne?`,
        answers: [`Sirius`, `Andromeda`, `Arcturus`, `##Trikolore`],
    },
    {
        id: "RIR",
        question: `Welcher Baum liefert Tannin für pharmazeutische Zwecke?`,
        answers: [`##Weißeiche`, `Weißulme`, `Weißtanne`, `Harzeibe`],
    },
    {
        id: "NLX",
        question: `Etwas zum Kauf anbieten, nennt man auch ... ?`,
        answers: [`beitelstellen`, `hobelgeben`, `bürstpreisen`, `##feilbieten`],
    },
    {
        id: "YNV",
        question: `Welche dieser Personen ist ein kanadischer Schauspieler?`,
        answers: [`Richard Dean Anderson`, `Sam Worthington`, `##Kiefer Sutherland`, `Sam Neill`],
    },
    {
        id: "FSK",
        question: `Was bedeutet intelligibel?`,
        answers: [`verständig, klug`, `aus Beobachtung und Versuch gewonnen`, `##wahrnehmbar, begreiflich`, `vernünftig, gewissenhaft`],
    },
    {
        id: "BTS",
        question: `Was versteht man unter Ikebana?`,
        answers: [`##Blumensteckkunst`, `Papierfalttechnik`, `Japanische Kampfsportart`, `Chinesisches Brettspiel`],
    },
    {
        id: "MPG",
        question: `Woraus wird Cayennepfeffer hergestellt?`,
        answers: [`Baumrinde`, `##Chilis`, `Vogeldung`, `Opossumleber`],
    },
    {
        id: "TPZ",
        question: `Wie viel Passagiere kann der neue Airbus A-380 befördern?`,
        answers: [`325`, `484`, `##555`, `610`],
    },
    {
        id: "XZA",
        question: `Einen Kalender nennt man auch ... ?`,
        answers: [`Kalenden`, `Managua`, `Timing`, `##Almanach`],
    },
    {
        id: "NJA",
        question: `Welches dieser Tiere wird am größten?`,
        answers: [`Kragenechse`, `##Leistenkrokodil`, `Alligator`, `Gangesgavial`],
    },
    {
        id: "KXF",
        question: `Wie nennt man eine Mischform zwischen ärmelloser Weste und Pullover?`,
        answers: [`Poncho`, `Plunder`, `Poloshirt`, `##Pullunder`],
    },
    {
        id: "KWY",
        question: `Welcher Baum stammt aus China?`,
        answers: [`##Trauerweide`, `Stieleiche`, `Rotbuche`, `Säulenpappel`],
    },
    {
        id: "VNY",
        question: `Wie wurde der Trabant in der DDR auch genannt?`,
        answers: [`##Rennpappe`, `Laufkarton`, `Flitzpapier`, `Sprintholz`],
    },
    {
        id: "URU",
        question: `In welchem Land befindet sich die Gartenanlage „Keukenhof“?`,
        answers: [`Luxemburg`, `##Niederlande`, `Belgien`, `Dänemark`],
    },
    {
        id: "WLG",
        question: `Wie lautet eine auf den Britischen Inseln gebräuchliche Bezeichnung für alte, meist kleine Häuser?`,
        answers: [`##Cottage`, `Coffage`, `Commage`, `Collage`],
    },
    {
        id: "ALT",
        question: `Ein gängiger Begriff aus dem Pokerspiel ist ... ?`,
        answers: [`stumm`, `lahm`, `##blind`, `taub`],
    },
    {
        id: "TCY",
        question: `Was haben Pilze im Unterschied zu Pflanzen nicht?`,
        answers: [`##Chlorophyll`, `Mitochondrien`, `Zellkerne`, `Ribosomen`],
    },
    {
        id: "HVH",
        question: `Ein Pedant ist ... ?`,
        answers: [`sehr unvorsichtig`, `extrem vorlaut`, `##übertrieben genau`, `besonders schweigsam`],
    },
    {
        id: "VTV",
        question: `Wer ist kein Mitglied der Kelly Family?`,
        answers: [`Angelo Gabriel Kelly`, `##Charles Anthony Kelly`, `Maria Patricia Kelly`, `Michael Patrick Kelly`],
    },
    {
        id: "FLO",
        question: `Semantik ist eine Teildisziplin der ... ?`,
        answers: [`Propädeutik`, `Logik`, `##Sprachwissenschaft`, `Psychologie`],
    },
    {
        id: "SDO",
        question: `Was ist eine Obligation?`,
        answers: [`ein Pfandbrief`, `##eine Schuldverschreibung`, `ein Grundpfandrecht`, `eine Wandelanleihe`],
    },
    {
        id: "MTD",
        question: `Zu welchem Staat gehören die Azoren?`,
        answers: [`Kanada`, `Italien`, `Frankreich`, `##Portugal`],
    },
    {
        id: "ULC",
        question: `Was bedeutet desavouieren?`,
        answers: [`widerrufen`, `##bloßstellen`, `betrügen`, `nötigen`],
    },
    {
        id: "BZA",
        question: `Was ist die prozentuale Darstellung von ⅛?`,
        answers: [`25%`, `8%`, `##12,5%`, `16,5%`],
    },
    {
        id: "AAB",
        question: `Welches Organ bildet die Gallenflüssigkeit?`,
        answers: [`##Leber`, `Zirbeldrüse`, `Nebenniere`, `Gallenblase`],
    },
    {
        id: "YRV",
        question: `Was bedeutet Gravidität?`,
        answers: [`Schwerkraft`, `##Schwangerschaft`, `Wachstum`, `Schwere eines Säuglings`],
    },
    {
        id: "IRQ",
        question: `Was ist ein echtes Spannungsmessgerät?`,
        answers: [`Elektromotor`, `##Elektrometer`, `Potentiometer`, `Galvanometer`],
    },
    {
        id: "AIJ",
        question: `Welches Rosengewächs hat weiße Blüten und eine fleischige rote Sammelnussfrucht?`,
        answers: [`Himbeere`, `Heidelbeere`, `Brombeere`, `##Erdbeere`],
    },
    {
        id: "DNH",
        question: `Welches Meerestier hat keine natürlichen Feinde?`,
        answers: [`Nilpferd`, `##weiße Hai`, `Blauwal`, `Elefant`],
    },
    {
        id: "VMI",
        question: `Die Hindenburg brannte, weil sie gefüllt war mit?`,
        answers: [`Helium`, `Knallgas`, `##Wasserstoff`, `Kerosin`],
    },
    {
        id: "NGH",
        question: `Blau, Grün und Rot ergeben in additiver Farbmischung ... ?`,
        answers: [`Gelb`, `##Weiß`, `Schwarz`, `Braun`],
    },
    {
        id: "FWL",
        question: `Wie viele Zehen haben Vögel?`,
        answers: [`2`, `3`, `##4`, `5`],
    },
    {
        id: "RTG",
        question: `Wie lautet das deutsche Wort für Insekt?`,
        answers: [`Kriechtier`, `##Kerbtier`, `Kleintier`, `Flügeltier`],
    },
    {
        id: "ABI",
        question: `Welche Frequenz hat Hausstrom in Mitteleuropa?`,
        answers: [`220 Hertz`, `110 Hertz`, `85 Hertz`, `##50 Hertz`],
    },
    {
        id: "VEQ",
        question: `In welchem US-Bundesstaat befindet sich die Stadt Santa Monica?`,
        answers: [`Hawaii`, `Texas`, `##Kalifornien`, `Florida`],
    },
    {
        id: "WIP",
        question: `Welches dieser Länder liegt nicht in Afrika?`,
        answers: [`Marokko`, `##Indonesien`, `Ghana`, `Kenia`],
    },
    {
        id: "NZV",
        question: `Wie ist die Bezeichnung für einen ausgezeichneten Fachmann?`,
        answers: [`Coryfee`, `Korifä`, `##Koryphäe`, `Coryfee`],
    },
    {
        id: "WGA",
        question: `Ein Brand von größerem Ausmaß nennt man auch ... ?`,
        answers: [`Brandbroiler`, `##Feuersbrunst`, `Glutbrechung`, `Flammenbrust`],
    },
    {
        id: "VWY",
        question: `Wie heißt ein Behälter für den Transport von Bargeld, den man z.B. in den Nachttresor einwirft?`,
        answers: [`Geldgeschoss`, `Geldgranate`, `##Geldbombe`, `Geldkugel`],
    },
    {
        id: "RVQ",
        question: `Wie wird der englische „Christmas Pudding“ noch genannt?`,
        answers: [`Cottage Pudding`, `Brandy Butter`, `Bread-and-Butter Pudding`, `##Plum Pudding`],
    },
    {
        id: "HXO",
        question: `Welches dieser Metalle hat die größte Dichte?`,
        answers: [`Kupfer`, `Silber`, `##Quecksilber`, `Blei`],
    },
    {
        id: "ZGN",
        question: `Wie viele Spieler hat eine Volleyballmannschaft?`,
        answers: [`5`, `##6`, `7`, `8`],
    },
    {
        id: "VPK",
        question: `In welcher Wissenschaft tauchte „Schrödingers Katze“ auf?`,
        answers: [`Astronomie`, `Optik`, `Zoologie`, `##Quantenmechanik`],
    },
    {
        id: "AUC",
        question: `Was verstand man in der DDR unter Bückware?`,
        answers: [`Waren aus dem westlichen Ausland`, `##Mangelware`, `Waren aus sozialistischen Bruderländern`, `Luxusartikel`],
    },
    {
        id: "NCG",
        question: `Wie groß ist die Winkelsumme in einem Parallelogramm?`,
        answers: [`180°`, `270°`, `##360°`, `540°`],
    },
    {
        id: "TPP",
        question: `Wie nennt man die Studie von Sternen und anderen Himmelskörpern?`,
        answers: [`##Astronomie`, `Astrologie`, `Anthropologie`, `Aerodynamik`],
    },
    {
        id: "XBV",
        question: `Wie viele Quadratmeter sind ein Hektar?`,
        answers: [`100`, `1000`, `##10000`, `100000`],
    },
    {
        id: "QTQ",
        question: `Welche Farbe hat das Blut von Tintenfischen?`,
        answers: [`##Blau`, `Grün`, `Rot`, `Gelb`],
    },
    {
        id: "XZQ",
        question: `Welcher Tee lindert fiebrige Erkältungen?`,
        answers: [`Fencheltee`, `##Kamillentee`, `Salbeitee`, `Pfefferminztee`],
    },
    {
        id: "XHN",
        question: `Welches Körperteil wird bei der Mammographie untersucht?`,
        answers: [`Herz`, `##Brust`, `Blase`, `Magen`],
    },
    {
        id: "SXS",
        question: `Welche Tiere haben Facettenaugen?`,
        answers: [`Katzen`, `##Libellen`, `Fledermäuse`, `Schlangen`],
    },
    {
        id: "KNX",
        question: `Was sind „Betthupferl“?`,
        answers: [`##kleine Süßigkeiten`, `kleine Seifen`, `kleine Äpfel`, `kleine Würstchen`],
    },
    {
        id: "ABK",
        question: `Was versteht man u.a. unter einem „Bubikopf“?`,
        answers: [`Wollmütze`, `Fahrradhelm`, `##Frisur`, `Hochzeitsschleier`],
    },
    {
        id: "UAF",
        question: `Was bezeichnet man auch als „14. Monatsgehalt“?`,
        answers: [`Kindergeld`, `##Urlaubsgeld`, `Krankenhaustagegeld`, `Trinkgeld`],
    },
    {
        id: "BOV",
        question: `Aus welchem Land stammte der Maler Vincent van Gogh?`,
        answers: [`Frankreich`, `Belgien`, `Italien`, `##Niederlande`],
    },
    {
        id: "LUJ",
        question: `Welcher Berufsstand trägt einen „Südwester“?`,
        answers: [`Bäcker`, `Altenpfleger`, `Metzger`, `##Seefahrer`],
    },
    {
        id: "CVS",
        question: `Welche Art von Toiletten wird in Flugzeugen verwendet?`,
        answers: [`##Vakuumtoiletten`, `Donnerbalken`, `Plumpsklos`, `Chemie-Toiletten`],
    },
    {
        id: "ZOU",
        question: `Wie nennt man einen „vorsätzlich falschen Eid“?`,
        answers: [`##Meineid`, `Boseid`, `Falscheid`, `Krummeid`],
    },
    {
        id: "XTO",
        question: `Wie nennt man die Tätigkeit des Hufschmieds, wenn er Tierhufe mit Hufeisen ausrüstet?`,
        answers: [`Verhauen`, `##Beschlagen`, `Benageln`, `Beklopfen`],
    },
    {
        id: "DDS",
        question: `Was ist eine „Fürbitte“?`,
        answers: [`eine Bettelei`, `eine Entschuldigung`, `##ein Gebet`, `ein Taufritual`],
    },
    {
        id: "AMG",
        question: `Was kann man abbauen?`,
        answers: [`Unglücksgold`, `##Pechkohle`, `Kummersilber`, `Schicksalseisen`],
    },
    {
        id: "WHA",
        question: `Wie wird ein „Befähigungsnachweis“ für Hundehalter bezeichnet?`,
        answers: [`##Hundeführerschein`, `Hundezeugnis`, `Hundesiegel`, `Hundeurkunde`],
    },
    {
        id: "LCC",
        question: `Wie werden Eier von Meeresbewohnern, z.B. von Fischen, genannt?`,
        answers: [`Fogen`, `Logen`, `Wogen`, `##Rogen`],
    },
    {
        id: "JRJ",
        question: `Was ist ein „Blizzard“?`,
        answers: [`##starker Schneesturm`, `Fotoeinstellung`, `Musikrichtung`, `akustisches Signal`],
    },
    {
        id: "JGA",
        question: `Was stellt ein Küfer her?`,
        answers: [`##Fässer`, `Fahrräder`, `Kosmetik`, `Kleider`],
    },
    {
        id: "IBO",
        question: `Welches Tier wird auch als „Teckel“ bezeichnet?`,
        answers: [`Terrier`, `Dachs`, `##Dackel`, `Fasan`],
    },
    {
        id: "SBV",
        question: `Was versteht man unter einen Seelenstriptis?`,
        answers: [`Leicht bekleidet tut der Seele gut`, `Ein Geheimnis verraten`, `##Offenbarung persönlicher Dinge`, `Ich sage alles was mir durch den Kopf geht`],
    },
    {
        id: "FQW",
        question: `Was versteht man unter dem Dreiklang der Intelligenz?`,
        answers: [`##Bauch-Kopf-Herz`, `Kopf-Herz-Bauch`, `Herz-Bauch-Kopf`, `Kopf-Bauch-Herz`],
    },

    
    // {
    //     id: "MDC",
    //     question: `Um was kümmert sich der „Barista“?`,
    //     answers: [`Tischdeko`, `Kuchen`, `##Kaffee`, `Menü`],
    // },
    // {
    //     id: "JIB",
    //     question: `Wer gilt, zusammen mit seinem Geschäftspartner Jacob Davis, als Erfinder der „Jeans“?`,
    //     answers: [`Luciano Benetton`, `##Levi Strauss`, `Thomas Burberry`, `Tommy Hilfiger`],
    // },
    // {
    //     id: "KMP",
    //     question: `Ein Kleidungs-Accessoire, welches in die äußere Brusttasche des Sakkos gesteckt wird, nennt man ... ?`,
    //     answers: [`Stola`, `Negligè`, `##Einstecktuch`, `Gamasche`],
    // },
    // {
    //     id: "UYZ",
    //     question: `Ein Baribal ist ein Vertreter welcher Tierart?`,
    //     answers: [`##Bären`, `Affen`, `Gazellen`, `Raubkatzen`],
    // },
    // {
    //     id: "BVG",
    //     question: `Welche dieser Epochen ist der unseren zeitlich am nächsten?`,
    //     answers: [`Die Antike`, `Das Mittelalter`, `##Die Renaissance`, `Die Steinzeit`],
    // },
    // {
    //     id: "ZRS",
    //     question: `Wie lautet der chemische Name für den Tafelzucker?`,
    //     answers: [`Glukose`, `Laktose`, `##Saccharose`, `Fruktose`],
    // },
    // {
    //     id: "PRL",
    //     question: `Wie nannte man das Münzgeld der DDR?`,
    //     answers: [`Blue Chips`, `Kupferchips`, `##Aluchips`, `Messingchips`],
    // },
    // {
    //     id: "UGD",
    //     question: `Welcher Planet ist als der „Rote Planet“ bekannt?`,
    //     answers: [`##Mars`, `Venus`, `Jupiter`, `Merkur`],
    // },
    // {
    //     id: "PXJ",
    //     question: `Welcher Stoff macht den Panzer vieler Käfer widerstandsfähig?`,
    //     answers: [`Chimäre`, `Chinin`, `##Chitin`, `Chiffon`],
    // },
    // {
    //     id: "HJW",
    //     question: `In welchem Jahr wurde die Berliner Mauer errichtet?`,
    //     answers: [`1945`, `1958`, `##1961`, `1973`],
    // },
    // {
    //     id: "WHS",
    //     question: `Wofür steht die Redewendung „A und O“ wörtlich?`,
    //     answers: [`Aufgeräumt und Ordnung`, `##Alpha und Omega`, `Abgesichert und Ordentlich`, `Allumfassend und Operativ`],
    // },
    // {
    //     id: "SAW",
    //     question: `Wie heißt der Fischhändler in „Asterix“?`,
    //     answers: [`Stinkefix`, `Krabberix`, `Tuhnix`, `##Verleihnix`],
    // },
    // {
    //     id: "KYF",
    //     question: `Wie lautet der eigentliche Name von Zwerg Nase?`,
    //     answers: [`Johann`, `Max`, `Benjamin`, `##Jacob`],
    // },
    // {
    //     id: "DAL",
    //     question: `Was misst man mit einem EKG?`,
    //     answers: [`Hirnströme`, `Hautwiderstand`, `##Herzströme`, `Schmerzempfindung`],
    // },
    // {
    //     id: "WYR",
    //     question: `Was ist ein Exzentriker?`,
    //     answers: [`Ein künstlich erzeugter Mensch`, `Ein Mensch mit Fehlbildungen`, `##Ein absonderlicher Mensch`, `Ein geistig eingeschränkter Mensch`],
    // },
    // {
    //     id: "QZQ",
    //     question: `Welcher Vogel wird fachsprachlich „Pica pica“ genannt?`,
    //     answers: [`Rabe`, `##Elster`, `Sperber`, `Uhu`],
    // },
    // {
    //     id: "SDR",
    //     question: `Was ist ein Querulant?`,
    //     answers: [`Ein dreidimensionales Bild auf einer ebenen Fläche`, `##Ein lästiger Mensch, der sich ständig beschwert`, `Eine tragbare Stereoanlage`, `Ein Holzbehälter für feine Zigarren`],
    // },
    // {
    //     id: "ZAA",
    //     question: `Wofür steht E-Mail?`,
    //     answers: [`electric mail`, `##electronic mail`, `endurance mail`, `emission mail`],
    // },
    // {
    //     id: "GLI",
    //     question: `Wie nennt man eine Verknüpfung mit anderen Textstellen, Medien oder Dokumenten?`,
    //     answers: [`URL`, `Hub`, `Bug`, `##Link`],
    // },
    // {
    //     id: "HNC",
    //     question: `Was bedeutet das Wort konisch?`,
    //     answers: [`kugelförmig`, `keilförmig`, `kreisförmig`, `##kegelförmig`],
    // },
    // {
    //     id: "LYZ",
    //     question: `Wie schrieben die Römer die Zahl 500?`,
    //     answers: [`A`, `##D`, `I`, `X`],
    // },
    // {
    //     id: "AKC",
    //     question: `Wie lautet der Fachausdruck für die Bauchspeicheldrüse?`,
    //     answers: [`Larynx`, `Epiglottis`, `Splen`, `##Pankreas`],
    // },
    // {
    //     id: "KKR",
    //     question: `Was enthielt echte Salami ursprünglich?`,
    //     answers: [`Nelken`, `##Eselfleisch`, `Lorbeer`, `Kalbsleber`],
    // },
    // {
    //     id: "SKJ",
    //     question: `Wie heißen die spiralig gedrehten Teigwaren?`,
    //     answers: [`Foyer`, `##Fusilli`, `Fossil`, `Fumé`],
    // },
    // {
    //     id: "UUL",
    //     question: `Ein unheildrohendes Zeichen nennt man auch?`,
    //     answers: [`Monokel`, `##Menetekel`, `Orakel`, `Methusalem`],
    // },
    // {
    //     id: "SZA",
    //     question: `Wie hieß der Gegenpol zur NATO im Kalten Krieg?`,
    //     answers: [`##Warschauer Pakt`, `Prager Pakt`, `Minsker Pakt`, `Dresdner Pakt`],
    // },
    // {
    //     id: "HFU",
    //     question: `Welches Verkehrsmittel verursacht die wenigsten Unfälle bezogen auf Personenkilometer?`,
    //     answers: [`PKW`, `Bahn`, `Bus`, `##Schiff`],
    // },
    // {
    //     id: "JXO",
    //     question: `Welcher Körper entsteht, wenn man ein gleichschenkliges Dreieck um seine Symmetrieachse rotieren lässt?`,
    //     answers: [`Zylinder`, `Kugel`, `##Kegel`, `Pyramide`],
    // },
    // {
    //     id: "ONG",
    //     question: `Von welchem nur 321 Meter hohen Berg wird behauptet, der meistbestiegene Europas zu sein?`,
    //     answers: [`Brocken`, `Zugspitze`, `##Drachenfels`, `Feldberg`],
    // },
    // {
    //     id: "TXM",
    //     question: `Wie viel Meter legt man zurück, während man im Auto bei 120 km/h für 3 Sekunden auf sein Handy schaut?`,
    //     answers: [`Ungefähr 30 Meter`, `Knapp 50 Meter`, `Etwa 80 Meter`, `##Rund 100 Meter`],
    // },
    // {
    //     id: "EBS",
    //     question: `Was war hierzulande bis in die 1950er noch gang und gäbe?`,
    //     answers: [`Beamtinnenkommunion`, `Krankenschwesternkollekte`, `##Lehrerinnenzölibat`, `Sekretärinnenbeichte`],
    // },
    // {
    //     id: "OML",
    //     question: `Welches Tier ist ein Galagos?`,
    //     answers: [`Hase`, `Vogel`, `Schmetterling`, `##Halbaffe`],
    // },
    // {
    //     id: "XKC",
    //     question: `Wie lautet das Elementsymbol für Zinn?`,
    //     answers: [`##Sn`, `Sb`, `Zn`, `Zi`],
    // },
    // {
    //     id: "ZUY",
    //     question: `Wie wird Dinkelweizen noch genannt?`,
    //     answers: [`Rotkorn`, `Braunkorn`, `Gelbkorn`, `##Grünkorn`],
    // },
    // {
    //     id: "NDU",
    //     question: `Wie alt können Elefanten in etwa werden?`,
    //     answers: [`20 Jahre`, `50 Jahre`, `##70 Jahre`, `100 Jahre`],
    // },
    // {
    //     id: "AIO",
    //     question: `Wie groß ist das maximale Atmungsvolumen eines Erwachsenen?`,
    //     answers: [`##4-5 Liter`, `1,2 Liter`, `ca. 6 Liter`, `0,5 Liter`],
    // },
    // {
    //     id: "GHK",
    //     question: `Welche Einheit ist gesetzlich nicht mehr zulässig?`,
    //     answers: [`Joule`, `Newton`, `Zoll`, `##Kalorie`],
    // },
    // {
    //     id: "RID",
    //     question: `Unter welchem Namen ist uns Carl Dieter Heckscher besser bekannt?`,
    //     answers: [`Dieter Hallervorden`, `Kalle Pohl`, `Karl Dall`, `##Dieter Thomas Heck`],
    // },
    // {
    //     id: "NVU",
    //     question: `In welchem Film taucht das Raumschiff Nostromo auf?`,
    //     answers: [`E.T.`, `Star Trek`, `##Alien`, `Star Wars`],
    // },
    // {
    //     id: "NSZ",
    //     question: `Wie hieß die einzige Tütensuppe der DDR?`,
    //     answers: [`Suppini`, `Suppino`, `Suppine`, `##Suppina`],
    // },
    // {
    //     id: "UWP",
    //     question: `Was ist der Hauptbestandteil von Guacamole?`,
    //     answers: [`Erdbeeren`, `##Avocado`, `Tomaten`, `Kartoffeln`],
    // },
    // {
    //     id: "YUS",
    //     question: `Was ist Guano?`,
    //     answers: [`Eine Affenart`, `##Vogelkot`, `Eine Droge`, `Ein Aromastoff`],
    // },
    // {
    //     id: "ZIO",
    //     question: `Was ist ein Verklicker?`,
    //     answers: [`Ein Erklärer der Spielregeln`, `Fehler beim Murmelspiel`, `##Eine Windfahne am Segelboot`, `Eine falsche Taste drücken`],
    // },
    // {
    //     id: "XNE",
    //     question: `Welches Zebra gibt es nicht?`,
    //     answers: [`Bergzebra`, `##Waldzebra`, `Grevyzebra`, `Steppenzebra`],
    // },
    // {
    //     id: "DLM",
    //     question: `„A cappella“ bezeichnet ... ?`,
    //     answers: [`Eine Kapelle innerhalb eines Doms`, `Ein großes Symphonieorchester`, `##Gesang ohne Instrumentalbegleitung`, `Folkloregruppe mit Musikbegleitung`],
    // },
    // {
    //     id: "CWC",
    //     question: `Ein deutsches Wort für Diagnose ist ... ?`,
    //     answers: [`Krankheit`, `Vorhersage`, `Widerspruch`, `##Befund`],
    // },
    // {
    //     id: "MNG",
    //     question: `Was ist kein chinesisches Tierkreiszeichen?`,
    //     answers: [`Schlange`, `Ratte`, `Drache`, `##Steinbock`],
    // },
    // {
    //     id: "YKI",
    //     question: `Was kann man nicht essen?`,
    //     answers: [`Kebab`, `Kümmel`, `Kurkuma`, `##Kuruma`],
    // },
    // {
    //     id: "ISV",
    //     question: `Mit welchen Worten bekunden Franzosen starke Zuneigung?`,
    //     answers: [`Je t´ame`, `##Je t´aime`, `Je t´dam`, `Je t´aim`],
    // },
    // {
    //     id: "BRU",
    //     question: `Wie heißt der oberste Halswirbel, der den Kopf trägt?`,
    //     answers: [`##Atlas`, `Bypass`, `Vene`, `Aorta`],
    // },
    // {
    //     id: "POP",
    //     question: `Wie lautet die Domainendung für Österreich?`,
    //     answers: [`.or`, `.au`, `##.at`, `.oe`],
    // },
    // {
    //     id: "KKG",
    //     question: `Wieviel kw sind 100 PS?`,
    //     answers: [`##ca. 74 kw`, `ca. 128 kw`, `ca. 86 kw`, `ca. 67 kw`],
    // },
    // {
    //     id: "MBB",
    //     question: `Wie lautet der wirkliche Vorname der Schauspielerin Jodie Foster?`,
    //     answers: [`Judith`, `##Alicia`, `Julia`, `Sarah`],
    // },
    // {
    //     id: "SOZ",
    //     question: `Welche Frucht nennt man auch „chinesische Stachelbeere“?`,
    //     answers: [`Papaya`, `##Kiwi`, `Litschi`, `Ananas`],
    // },
    // {
    //     id: "TNQ",
    //     question: `Wo entstehen die weißen Blutkörperchen beim Menschen?`,
    //     answers: [`Schilddrüse`, `Leber`, `Milz`, `##Knochenmark`],
    // },
    // {
    //     id: "PJP",
    //     question: `Unter welchem Namen vereinigten sich ab 1964 Warenhäuser in Großstädten der DDR?`,
    //     answers: [`Intershop`, `##Centrum`, `Kaufland`, `KaDeOs`],
    // },
    // {
    //     id: "BAE",
    //     question: `Über welchen Teil seines Lohnes kann der Arbeitnehmer nicht frei verfügen?`,
    //     answers: [`Weihnachtsgeld`, `Deputat`, `Naturallohn`, `##Investivlohn`],
    // },
    // {
    //     id: "CIZ",
    //     question: `Welcher Stoff ist ein Nichtleiter?`,
    //     answers: [`Silizium`, `Kupfer`, `Graphit`, `##Diamant`],
    // },
    // {
    //     id: "YLJ",
    //     question: `Wie hieß die erste Raumfähre der NASA?`,
    //     answers: [`Challenger`, `Discovery`, `##Columbia`, `Sojourner`],
    // },
    // {
    //     id: "QSL",
    //     question: `Dinkel ist eine ... ?`,
    //     answers: [`##Weizenart`, `Maisart`, `Haferart`, `Hirseart`],
    // },
    // {
    //     id: "DGI",
    //     question: `Was beherrschte Louis Armstrong, wenn er sang?`,
    //     answers: [`Rave`, `##Scat`, `Techno`, `Rap`],
    // },
    // {
    //     id: "HCO",
    //     question: `Woraus wird Tofu gemacht?`,
    //     answers: [`Schafskäse`, `Eierstich`, `##Sojaquark`, `Butterkäse`],
    // },
    // {
    //     id: "TZW",
    //     question: `Wodurch kann nach bürgerlichem Recht eine Ehe nicht aufgelöst werden?`,
    //     answers: [`Nichtigerklärung`, `Scheidung`, `Aufhebung`, `##Getrennte Wohnorte`],
    // },
    // {
    //     id: "SLZ",
    //     question: `Zum Abstreichen überschüssiger Druckfarbe benutzt man eine ... ?`,
    //     answers: [`##Rakel`, `Takel`, `Makel`, `Texel`],
    // },
    // {
    //     id: "VAT",
    //     question: `Das Zentrum des Schriftzugs der Band AC/DC ist ... ?`,
    //     answers: [`eine Rose`, `eine Pistole`, `##ein Blitz`, `ein Totenkopf`],
    // },
];

export default knowledge;